// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Certification_title-wrapper__cDiSX {
  text-align: center;
  font-size: 1.2rem;
  padding: 20px;
  font-weight: 800;
}

.Certification_certificate-title__7tDxT {
  padding: 10px;
}

.Certification_message__\\+YLvB {
  margin-top: 10px;
}

.Certification_accordion__ZDiOc {
  margin-top: 1rem;
}

.Certification_accordion-wrapper__Wqn9K {
  padding: 0 1rem 4rem 1rem;
  max-width: 30rem;
  margin: auto;
}

.Certification_home-wrapper__CRugw {
  background: rgb(243, 227, 213);
  height: 100%;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/certification/Certification.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,aAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,yBAAA;EACA,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,8BAAA;EACA,YAAA;EACA,gBAAA;AACF","sourcesContent":[".title-wrapper {\n  text-align: center;\n  font-size: 1.2rem;\n  padding: 20px;\n  font-weight: 800;\n}\n\n.certificate-title {\n  padding: 10px;\n}\n\n.message {\n  margin-top: 10px;\n}\n\n.accordion {\n  margin-top: 1rem;\n}\n\n.accordion-wrapper {\n  padding: 0 1rem 4rem 1rem;\n  max-width: 30rem;\n  margin: auto;\n}\n\n.home-wrapper {\n  background: rgb(243 227 213);\n  height: 100%;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title-wrapper": `Certification_title-wrapper__cDiSX`,
	"certificate-title": `Certification_certificate-title__7tDxT`,
	"message": `Certification_message__+YLvB`,
	"accordion": `Certification_accordion__ZDiOc`,
	"accordion-wrapper": `Certification_accordion-wrapper__Wqn9K`,
	"home-wrapper": `Certification_home-wrapper__CRugw`
};
export default ___CSS_LOADER_EXPORT___;
