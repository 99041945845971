export const examStage = [
  {
    name: "午後1",
    value: "afternoon1",
  },
  { name: "午後2", value: "afternoon2" },
];

export const ouyouSection = [
  {
    name: "情報セキュリティ",
    value: "section1",
  },
  { name: "経営戦略", value: "section2" },
  {
    name: "情報戦略",
    value: "section3",
  },
  {
    name: "戦略立案・コンサルティング技法",
    value: "section4",
  },
  {
    name: "プログラミング",
    value: "section5",
  },
  {
    name: "システムアーキテクチャ",
    value: "section6",
  },
  {
    name: "ネットワーク",
    value: "section7",
  },
  {
    name: "データベース",
    value: "section8",
  },
  {
    name: "組み込みシステム開発",
    value: "section9",
  },
  {
    name: "情報システム開発",
    value: "section10",
  },
  {
    name: "プロジェクトマネジメント",
    value: "section11",
  },
  {
    name: "サービスマネジメント",
    value: "section12",
  },
  {
    name: "システム監査",
    value: "section13",
  },
];

export const sekisupeSection = [
  {
    name: "大問1",
    value: "section1",
  },
  { name: "大問2", value: "section2" },
  {
    name: "大問3",
    value: "section3",
  },
  {
    name: "大問4",
    value: "section4",
  },
];

export const section = [
  {
    name: "大問1",
    value: "section1",
  },
  { name: "大問2", value: "section2" },
  {
    name: "大問3",
    value: "section3",
  },
];

export const question = [
  {
    name: "設問1",
    value: "question1",
  },
  { name: "設問2", value: "question2" },
  {
    name: "設問3",
    value: "question3",
  },
  {
    name: "設問4",
    value: "question4",
  },
  {
    name: "設問5",
    value: "question5",
  },
  {
    name: "設問6",
    value: "question6",
  },
  {
    name: "設問7",
    value: "question7",
  },
  {
    name: "設問8",
    value: "question8",
  },
  {
    name: "設問9",
    value: "question9",
  },
  {
    name: "設問10",
    value: "question10",
  },
  {
    name: "設問11",
    value: "question11",
  },
];

export const difficulty = [
  {
    name: "難化",
    value: "difficult",
  },
  {
    name: "易化",
    value: "easy",
  },
];

export const filterQuestion = [
  {
    name: "全て",
    value: "all",
  },
  {
    name: "設問1",
    value: "question1",
  },
  { name: "設問2", value: "question2" },
  {
    name: "設問3",
    value: "question3",
  },
  {
    name: "設問4",
    value: "question4",
  },
  {
    name: "設問5",
    value: "question5",
  },
  {
    name: "設問6",
    value: "question6",
  },
  {
    name: "設問7",
    value: "question7",
  },
  {
    name: "設問8",
    value: "question8",
  },
  {
    name: "設問9",
    value: "question9",
  },
  {
    name: "設問10",
    value: "question10",
  },
  {
    name: "設問11",
    value: "question11",
  },
];
