import styles from "./AnswerMorningListPage.module.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  GetCertification,
  GetCertificationContext,
} from "../../hooks/reducer/CertificationReducer";
import { createTitle } from "../../utils/header-util";
import {
  GetAnswerMorningContext,
  GetAnswerMorningResponse,
} from "../../hooks/reducer/AnswerMorningReducer";
import { MetaData, metaAnswerForHelmet } from "../../../constant/meta";
import { Helmet } from "react-helmet";
import HeaderLayout from "../../organism/header-layout/HeaderLayout";
import AnswerMorningList from "../../organism/answer-morning-list/AnswerMorningList";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ANSWER_MORNING1 } from "../../../constant/const";
import { useCertification } from "../../hooks/custom-hooks/useCertification";
import { useHelmetHeader } from "../../hooks/custom-hooks/useHelmetHeader";

const AnswerMorningListPage = () => {
  // ここもtemplate化できる
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const { metaProp } = useHelmetHeader();
  const certificationId = query?.get("pageId");
  const seazonId = query?.get("seazon");
  const level = query?.get("level");
  const [examlevel, setexamlevel] = useState<"MORNING1" | "MORNING2">(
    level === ANSWER_MORNING1 ? "MORNING1" : "MORNING2"
  );
  const [answers, setAnswers] = useState<GetAnswerMorningResponse[]>([]);
  const getAnswerMorningContext = useContext(GetAnswerMorningContext);
  const { certifications } = useCertification();
  const targetCertification = certifications?.filter(
    (c) => c.certificationId === certificationId
  )[0];

  useEffect(() => {
    if (level === ANSWER_MORNING1) {
      setexamlevel("MORNING1");
    } else {
      setexamlevel("MORNING2");
    }

    const pageId = level === ANSWER_MORNING1 ? "MORNING1" : certificationId;

    const params = {
      pageId: pageId,
      seazon: seazonId,
    };
    getAnswerMorningContext?.refetch?.(params);
  }, []);

  useEffect(() => {
    const answers = getAnswerMorningContext.state.data;

    if (answers == null) {
      return;
    }

    setAnswers(answers);
  }, [getAnswerMorningContext.state.data]);

  return (
    <div className={styles.container}>
      {metaProp && (
        <Helmet
          title={metaProp.title}
          meta={[{ name: "description", content: metaProp.description }]}
        />
      )}
      <HeaderLayout
        title={createTitle(targetCertification ?? null, seazonId, examlevel)}
        backgroundColor="#FFFFFF"
        color="#333"
        textAlign="center"
        BackIconComponent={<ArrowBackIosIcon fontSize="small" />}
        isMenu={true}
      >
        <div className={styles.answer}>
          <AnswerMorningList examlevel={examlevel} answers={answers} />
        </div>
      </HeaderLayout>
    </div>
  );
};

export default AnswerMorningListPage;
