import { Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useState } from "react";
type TextboxProps = {
  name: string;
  id: string;
  defaultText?: string;
  placeHolder?: string;
  handleTextboxChange?: (event: TextFormResult) => void;
  handleNumberChange?: (event: NumberFormResult) => void;
  type?: "text" | "number" | "password";
  rows?: number;
  label?: string;
  onClickRightButton?: () => void;
};

export type TextFormResult = {
  value: string;
  name: string;
};

export type NumberFormResult = {
  value: number;
  name: string;
};

const Textbox = (props: TextboxProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [numberFormatError, setNumberFormatError] = useState(false);
  const [numberRangeError, setNumberRangeError] = useState(false);

  // 全角数字を半角数字に変換する関数
  const validateNumberInput = (input: string): string => {
    // 全角数字を半角数字に変換
    const halfWidthValue = input.replace(/[０-９]/g, (s) =>
      String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    );
    // 半角数字以外を削除
    return halfWidthValue;
    // .replace(/[^0-9]/g, "");　エラー表示させるためにコメントアウト
  };

  const handleChangeNumber = (value: number) => {
    if (!Number.isInteger(Number(value))) {
      setNumberFormatError(true);
    } else if (value < 0 || value > 100) {
      setNumberRangeError(true);
    } else {
      setNumberRangeError(false);
      setNumberFormatError(false);
    }
    if (props.handleNumberChange) {
      props.handleNumberChange({ value, name: props.name });
    }
  };

  const handleChange = (value: string) => {
    if (props.handleTextboxChange) {
      props.handleTextboxChange({ value, name: props.name });
    }
  };

  if (props.type === "password") {
    return (
      <div>
        <TextField
          label={props.label}
          variant="outlined"
          type={showPassword ? "text" : "password"}
          onChange={(event) => handleChange(event.target.value)}
          fullWidth
          sx={{
            bgcolor: "background.paper",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  } else if (props.type === "number") {
    return (
      <div>
        <div>
          <TextField
            label={props.label}
            variant="outlined"
            id={props.id}
            multiline
            rows={props.rows}
            defaultValue={props.defaultText}
            placeholder={props.placeHolder}
            type="number"
            InputProps={{
              inputMode: "numeric", // モバイル端末で数字キーボードを表示 （ToDo）要確認
            }}
            fullWidth
            sx={{
              bgcolor: "background.paper",
            }}
            error={numberFormatError || numberRangeError}
            helperText={
              numberFormatError
                ? "数値は半角数値で入力してください"
                : numberRangeError
                ? "数値は0~100の範囲で入力してください"
                : ""
            }
            onChange={(event) => handleChangeNumber(Number(event.target.value))}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <TextField
          label={props.label}
          variant="outlined"
          id={props.id}
          multiline
          rows={props.rows}
          defaultValue={props.defaultText}
          placeholder={props.placeHolder}
          type="text"
          fullWidth
          sx={{
            bgcolor: "background.paper",
          }}
          onChange={(event) => handleChange(event.target.value)}
        />
      </div>
    );
  }
};
export default Textbox;
