// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SimpleModal_message__KsgFQ {
  font-size: 14px;
  font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/organism/simple-modal/SimpleModal.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;AACF","sourcesContent":[".message {\n  font-size: 14px;\n  font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": `SimpleModal_message__KsgFQ`
};
export default ___CSS_LOADER_EXPORT___;
