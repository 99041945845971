export const difficultyMapper = {
  difficult: "難化",
  easy: "易化",
};

export const sectionMapper = {
  section1: "問1",
  section2: "問2",
  section3: "問3",
  section4: "問4",
  section5: "問5",
  section6: "問6",
  section7: "問7",
  section8: "問8",
  section9: "問9",
  section10: "問10",
  section11: "問11",
};
