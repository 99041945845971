import React from "react";
import { useAtom } from "jotai";
import ModalWrapper, {
  modalState,
} from "../../molecules/modal-wrapper/ModalWrapper";
import styles from "./SimpleModal.module.scss";
export type SimpleModalProps = {
  // open: boolean;
  handleClose: () => void;
  message: string;
};

const SimpleModal = (props: SimpleModalProps) => {
  const [state, setState] = useAtom(modalState);
  const internalHandleClose = () => {
    setState(false);
    props.handleClose();
  };
  return (
    <>
      <ModalWrapper close={internalHandleClose} height="100px">
        <div className={styles.message}>
          {props.message.split("<br/>").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
      </ModalWrapper>
    </>
  );
};

export default SimpleModal;
