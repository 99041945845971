import styles from "./AnswerMorningFormPage.module.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetCertificationContext } from "../../hooks/reducer/CertificationReducer";
import { createTitle } from "../../utils/header-util";
import logoImage from "../../../static/images/header-icon.png";
import HeaderLayout from "../../organism/header-layout/HeaderLayout";
import { CertificationType } from "../certification/Certification";
import AnswerMorningForm from "../../organism/answer-morning-form/AnswerMorningForm";

const AnswerMorningFormPage = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const certificationId = query?.get("pageId");
  const seazonId = query?.get("seazon");
  const [certification, setCertification] = useState<CertificationType | null>(
    null
  );
  const getCertificationContext = useContext(GetCertificationContext);

  useEffect(() => {
    const params = {
      pageId: certificationId,
      seazon: seazonId,
    };

    getCertificationContext?.refetch?.(params);
  }, []);

  useEffect(() => {
    if (getCertificationContext?.state?.data?.[0] == null) {
      return;
    }
    setCertification(getCertificationContext.state.data[0]);
  }, [[getCertificationContext.state.data]]);

  return (
    <div className={styles.answerContainer}>
      <HeaderLayout
        title={createTitle(certification, seazonId)}
        backgroundColor="#8C7968"
        color="white"
        headerIcon={logoImage}
        isMenu={true}
      >
        <div>
          <AnswerMorningForm />
        </div>
      </HeaderLayout>
    </div>
  );
};

export default AnswerMorningFormPage;
