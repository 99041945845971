import axios from "axios";
import { atom } from "jotai";
import { State, useFetchBase, usePostBase } from "./BaseApi";

export type Like = {
  afternoonAnswerId: string;
  liked: boolean;
};

const url = process.env.REACT_APP_SERVER_PATH + "/likeAfternoon";

export const postLikeAtom = atom<State<Like> | null>(null);

export const usePostLike = () => {
  return usePostBase<Like>(url, postLikeAtom);
};
