export const examStage = [
  {
    name: "午後1",
    value: "afternoon1",
  },
  { name: "午後2", value: "afternoon2" },
];

export const ouyouSection = [
  {
    name: "大問1",
    value: "section1",
  },
  { name: "大問2", value: "section2" },
  {
    name: "大問3",
    value: "section3",
  },
  {
    name: "大問4",
    value: "section4",
  },
  {
    name: "大問5",
    value: "section5",
  },
  {
    name: "大問6",
    value: "section6",
  },
  {
    name: "大問7",
    value: "section7",
  },
  {
    name: "大問8",
    value: "section8",
  },
  {
    name: "大問9",
    value: "section9",
  },
  {
    name: "大問10",
    value: "section10",
  },
  {
    name: "大問11",
    value: "section11",
  },
];

export const sekisupeSection = [
  {
    name: "大問1",
    value: "section1",
  },
  { name: "大問2", value: "section2" },
  {
    name: "大問3",
    value: "section3",
  },
  {
    name: "大問4",
    value: "section4",
  },
];

export const section = [
  {
    name: "大問1",
    value: "section1",
  },
  { name: "大問2", value: "section2" },
  {
    name: "大問3",
    value: "section3",
  },
];

export const difficulty = [
  {
    name: "難化",
    value: "difficult",
  },
  {
    name: "易化",
    value: "easy",
  },
];

export const filterAnswer = [
  {
    name: "全て",
    value: "all",
  },
  {
    name: "大問1",
    value: "section1",
  },
  { name: "大問2", value: "section2" },
  {
    name: "大問3",
    value: "section3",
  },
  {
    name: "大問4",
    value: "section4",
  },
  {
    name: "大問5",
    value: "section5",
  },
  {
    name: "大問6",
    value: "section6",
  },
  {
    name: "大問7",
    value: "section7",
  },
  {
    name: "大問8",
    value: "section8",
  },
  {
    name: "大問9",
    value: "section9",
  },
  {
    name: "大問10",
    value: "section10",
  },
  {
    name: "大問11",
    value: "section11",
  },
];
