import { useContext, useState } from "react";
import { ErrorHandlerContext } from "../molecules/error-handler/ErrorHandler";
import axios from "axios";
import { PrimitiveAtom, useAtom } from "jotai";
import { errorAtom } from "../pages/error-handler/ErrorHandler";

export type State<T> = {
  status: "processing" | "success" | "error";
  data: T[] | null;
  error: any;
};

export const useFetchBase = <T, P>(
  url: string,
  atom: PrimitiveAtom<State<T> | null>
) => {
  const [state, setState] = useAtom(atom);
  const [errorState, setErrorState] = useAtom(errorAtom);

  const fetch = async (params: P) => {
    try {
      const response = await axios.get(url, { params });
      setState({
        status: "success",
        data: response.data,
        error: null,
      });

      return response.data;
    } catch (error) {
      setErrorState(error);
      setState({
        status: "error",
        data: null,
        error: error,
      });

      return null;
    }
  };

  const reset = () => {
    setState(null);
  };

  return { state, fetch, reset };
};

export const usePostBase = <T>(
  url: string,
  atom: PrimitiveAtom<State<T> | null>
) => {
  const [state, setState] = useAtom(atom);
  const [errorState, setErrorState] = useAtom(errorAtom);

  const post = async (data: T) => {
    try {
      const response = await axios.post(url, data);
      setState({
        status: "success",
        data: response.data.response,
        error: null,
      });

      return response.data;
    } catch (error) {
      console.log(error);
      setErrorState(error);
      setState({
        status: "error",
        data: null,
        error: error,
      });

      return null;
    }
  };

  const reset = () => {
    setState(null);
  };

  return { state, post, reset };
};
