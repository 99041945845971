import { FunctionComponent, useContext, useEffect, useState } from "react";
import styles from "./Certification.module.scss";
import { LoadingContext } from "../../molecules/loading/Loading";
import { GetCertificationContext } from "../../hooks/reducer/CertificationReducer";
import logoImage from "../../../static/images/header-icon.png";
import { Helmet } from "react-helmet";
import HeaderLayout from "../../organism/header-layout/HeaderLayout";
import { useCertification } from "../../hooks/custom-hooks/useCertification";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleButton from "../../atoms/button/SimpleButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { FetchAnswerAfternoonContext } from "../../hooks/reducer/AnswerAfternoonReducer";
import { GetChatMessageContext } from "../../hooks/reducer/ChatReducer";
import { GetAnswerMorningContext } from "../../hooks/reducer/AnswerMorningReducer";
import { useHelmetHeader } from "../../hooks/custom-hooks/useHelmetHeader";

export type Seazon = {
  seazonId: string;
  seazonName: string;
};

const Certification = () => {
  // 取得API呼び出す
  const { certifications } = useCertification();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const pageId = query?.get("pageId") ?? "OUYOU";
  const navigate = useNavigate();
  const { metaProp } = useHelmetHeader();

  const getCertificationContext = useContext(GetCertificationContext);
  const fetchAnswerAfternoonContext = useContext(FetchAnswerAfternoonContext);
  const fetchAnswerMorningContext = useContext(GetAnswerMorningContext);
  const getChatMessageContext = useContext(GetChatMessageContext);

  const targetCertification = certifications?.filter(
    (c) => c.certificationId === pageId
  )[0];
  const seazons = targetCertification?.seazon.sort(
    (a, b) => Number(b.seazonId) - Number(a.seazonId)
  );

  useEffect(() => {
    // 資格のページに来たらStateをリセットしておく
    getCertificationContext?.resetState?.();
    fetchAnswerAfternoonContext?.resetState?.();
    fetchAnswerMorningContext?.resetState?.();
    getChatMessageContext?.resetState?.();
  }, []);

  return (
    <div className={styles.homeWrapper}>
      {metaProp && (
        <Helmet
          title={metaProp.title}
          meta={[{ name: "description", content: metaProp.description }]}
        />
      )}
      <HeaderLayout
        title={["IT資格 速報掲示板"]}
        backgroundColor="#FFFFFF"
        color="#333"
        headerIcon={logoImage}
        isMenu={true}
      >
        <div className={styles.contentWrapper}>
          <div className={styles.titleWrapper}>
            <h2 className={styles.message}>
              {targetCertification?.certificationName}
            </h2>
          </div>
          <div className={styles.button}>
            <SimpleButton
              label="みんなの掲示板を見る"
              width="80%"
              color="redOrange"
              onClick={() => navigate(`/chat?pageId=${pageId}`)}
            ></SimpleButton>
          </div>
          <div className={styles.reportButton}>
            <SimpleButton
              label="受験体験記を見る"
              width="80%"
              color="transparentRedOrange"
              onClick={() => navigate(`/report?pageId=${pageId}`)}
            />
          </div>
          <div className={styles.titleWrapper}>
            <h2 className={styles.message}>みんなの解答</h2>
          </div>
          <div>
            {seazons?.map((s, i) => (
              <div className={styles.seazonArea} key={i}>
                <div className={styles.seazonName}>{s.seazonName}</div>
                {pageId !== "OUYOU" && (
                  <div className={styles.buttonArea}>
                    <SimpleButton
                      width="80%"
                      spaceBetween={true}
                      label={"午前I"}
                      RightIconComponent={
                        <ArrowForwardIosIcon fontSize="small" />
                      }
                      onClick={() => {
                        navigate(
                          `/answer-morning-list?pageId=${pageId}&seazon=${s.seazonId}&level=MORNING1`
                        );
                      }}
                    ></SimpleButton>
                  </div>
                )}
                <div className={styles.buttonArea}>
                  <SimpleButton
                    width="80%"
                    spaceBetween={true}
                    label={pageId === "OUYOU" ? "午前" : "午前II"}
                    RightIconComponent={
                      <ArrowForwardIosIcon fontSize="small" />
                    }
                    onClick={() =>
                      navigate(
                        `/answer-morning-list?pageId=${pageId}&seazon=${s.seazonId}`
                      )
                    }
                  ></SimpleButton>
                </div>
                <div className={styles.buttonArea}>
                  <SimpleButton
                    width="80%"
                    spaceBetween={true}
                    label={"午後"}
                    RightIconComponent={
                      <ArrowForwardIosIcon fontSize="small" />
                    }
                    onClick={() =>
                      navigate(
                        `/answer-afternoon-list?pageId=${pageId}&seazon=${s.seazonId}`
                      )
                    }
                  ></SimpleButton>
                </div>
              </div>
            ))}
          </div>
        </div>
      </HeaderLayout>
    </div>
  );
};

export default Certification;
