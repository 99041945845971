import {
  RefObject,
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Message } from "../../pages/chat-page/Chat";
import { toHHMM, toYYYYMMDDWithSlah } from "../../utils/date-util";
import styles from "./ChatMessage.module.scss";
import { useLocation } from "react-router-dom";
import {
  GetChatMessageContext,
  GetMessageParams,
} from "../../hooks/reducer/ChatReducer";
import CircularProgress from "@mui/material/CircularProgress";
import ReplyIcon from "@mui/icons-material/Reply";
import Linkify from "react-linkify";
import ChatFooter from "../chat-footer/ChatFooter";
import { LoadingContext } from "../../molecules/loading/Loading";

interface ChatMessageProps {
  messages: Message[];
}

type GroupedMessage = {
  date: string;
  messages: Message[];
};

const ChatMessage = (props: ChatMessageProps) => {
  const [groupedMessages, setGroupedMessages] = useState<GroupedMessage[]>([]);
  const [replyMessage, setReplyMessage] = useState<Message | null>(null);
  const [useRefMessage, setUseRefMessage] = useState<Message[]>([]);
  const { loadingState, changeLoadingState } = useContext(LoadingContext);
  const reloadProcessing = useRef(false);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const certificationId = query?.get("pageId");
  const messageEndRef = useRef<HTMLDivElement>(null);
  const getChatMessageContext = useContext(GetChatMessageContext);
  const messageRef = useRef<RefObject<HTMLInputElement>[]>([]);
  const groupingByDate = (messages: Message[]): GroupedMessage[] => {
    const groupedMessages: { [date: string]: Message[] } = {};

    messages.forEach((message) => {
      const time = toYYYYMMDDWithSlah(message.createTime);
      if (groupedMessages[time]) {
        groupedMessages[time].push(message);
      } else {
        groupedMessages[time] = [message];
      }
    });

    return Object.keys(groupedMessages).map((date, index) => ({
      date,
      messages: groupedMessages[date],
    }));
  };

  useEffect(() => {
    setGroupedMessages(groupingByDate(props.messages));
  }, [props]);

  useEffect(() => {
    const messagesList = groupedMessages.map((gm) => gm.messages);
    const temp = ([] as Message[]).concat(...messagesList);
    temp.forEach((message, index) => {
      messageRef.current[index] = createRef<HTMLInputElement>();
    });
    setUseRefMessage(temp);
    scrollBottom();
  }, [groupedMessages]);

  const reload = () => {
    changeLoadingState(true);
    // 連打させないためにゴニョゴニョやってる
    if (reloadProcessing.current) {
      return;
    }
    reloadProcessing.current = true;
    const params: GetMessageParams = {
      pageId: certificationId,
    };

    getChatMessageContext?.refetch?.(params);
  };

  useEffect(() => {
    reloadProcessing.current = false;
  }, [getChatMessageContext]);

  const scrollBottom = (smooth?: "smooth") => {
    messageEndRef.current?.scrollIntoView({
      behavior: smooth,
      block: "center",
    });
  };

  const reply = (message?: Message) => {
    if (message == null) {
      return;
    }
    setReplyMessage(message);
  };

  const moveReplyed = (m: Message) => {
    const index = useRefMessage.findIndex((rm) => rm._id === m.replyId);
    const target = messageRef.current[index];
    target.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

    // 削除前に既存のstyle要素があれば削除
    const existingStyleElement = document.getElementById("shakeAnimationStyle");
    if (existingStyleElement) {
      existingStyleElement.remove();
    }

    const keyframes = `
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-5px); }
        50% { transform: translateX(5px); }
        75% { transform: translateX(-5px); }
        100% { transform: translateX(0); }
      }
    `;

    // スタイル要素を作成し、CSSアニメーションを適用
    const styleElement = document.createElement("style");
    styleElement.textContent = keyframes;
    document.head.appendChild(styleElement);

    if (target.current == null) {
      return;
    }

    target.current.style.removeProperty("shake 0.5s ease-in-out 1s");
    target.current.style.transition = "none";
    target.current.style.animation = "shake 0.5s ease-in-out 1s";
  };
  const create = (index1: number, index2: number) => {
    let totalCount = 0;

    // TODO: うまく書きたい、、
    for (let i = 0; i < index1; i++) {
      totalCount += groupedMessages[i].messages.length;
    }

    totalCount += index2;
    return totalCount;
  };

  return (
    <>
      <div className={styles.chatArea}>
        {groupedMessages.map((gm, index1) => (
          <div key={index1}>
            <div className={styles.dateArea}>
              <span className={styles.date}>{gm.date}</span>
            </div>
            {gm.messages.map((m, index2) => (
              <div className={styles.messageBox} key={index2}>
                <div className={styles.messageWrapper}>
                  <div className={styles.messageId}>{m._id.slice(-5)}</div>
                  <div className={styles.messageContainer}>
                    <div
                      className={styles.messageArea}
                      ref={messageRef.current[create(index1, index2)]}
                    >
                      {m.replyId && (
                        <div
                          className={styles.replyId}
                          onClick={() => moveReplyed(m)}
                        >
                          @{m.replyId.slice(-5)}
                          <div className={styles.replyMessage}>
                            {m.replyMessage?.slice(0, 50)}
                          </div>
                        </div>
                      )}
                      <div className={styles.message}>
                        <Linkify>{m.message}</Linkify>
                      </div>
                    </div>
                    <span className={styles.time}>{toHHMM(m.createTime)}</span>
                    <div className={styles.reply} onClick={() => reply(m)}>
                      <ReplyIcon sx={{ color: "grey", fontSize: "1.2rem" }} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
        <div className={styles.bottomButton}>
          {/* ちょっとダサすぎる。。。 */}
          <div className={styles.updateButton} onClick={() => reload()}>
            最新のメッセージを取得する
          </div>
          {reloadProcessing.current && (
            <div className={styles.loading}>
              <CircularProgress size={20} />
            </div>
          )}
        </div>
        {/** refを利用して初期表示を一番下にする */}
        <div ref={messageEndRef}></div>
      </div>
      <ChatFooter
        background="rgb(255 255 255)"
        message={replyMessage}
        scroll={scrollBottom}
        setReplyMessage={setReplyMessage}
      />
    </>
  );
};

export default ChatMessage;
