import { Button } from "@mui/material";
import { CSSProperties, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ANSWER_MORNING1,
  ANSWER_MORNING_EIGHTY,
} from "../../../constant/const";
import { GetAnswerMorningResponse } from "../../hooks/reducer/AnswerMorningReducer";
import styles from "./AnswerMorningList.module.scss";
import SimpleButton from "../../atoms/button/SimpleButton";

interface AnswerAggregateMorningProps {
  answers: GetAnswerMorningResponse[];
  examlevel: "MORNING1" | "MORNING2";
}

type DisplayAnswer = GetAnswerMorningResponse & {
  maxAnswer: number;
  reliability: number;
};

const AnswerMorningList = (props: AnswerAggregateMorningProps) => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const certificationId = query?.get("pageId");
  const seazonId = query?.get("seazon");
  const [displayAnswers, setDisplayAnswers] = useState<DisplayAnswer[]>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setDisplayAnswers(
      props.answers.map((answer) => {
        const sum =
          answer.answerA + answer.answerB + answer.answerC + answer.answerD;
        const maxAnswer = Math.max(
          answer.answerA,
          answer.answerB,
          answer.answerC,
          answer.answerD
        );
        return {
          no: answer.no,
          answerA: answer.answerA,
          answerB: answer.answerB,
          answerC: answer.answerC,
          answerD: answer.answerD,
          maxAnswer,
          reliability: sum === 0 ? 0 : Math.round((maxAnswer / sum) * 100),
        };
      })
    );
  }, [props]);

  const createCellStyle = (
    answers: DisplayAnswer,
    targetAnswerNumber: number
  ): CSSProperties => {
    if (targetAnswerNumber === 0) {
      return {};
    }

    if (targetAnswerNumber === answers.maxAnswer) {
      return {
        backgroundColor: "#e6a11a",
        color: "white",
        fontWeight: "800",
      };
    }
    return {};
  };

  const reliabilityStyle = (answer: DisplayAnswer): CSSProperties => {
    if (answer.reliability >= 80) {
      return {
        color: "red",
      };
    } else if (answer.reliability >= 50) {
      return {
        color: "orange",
      };
    }
    return {};
  };

  const answer = () => {
    if (props.examlevel === ANSWER_MORNING1) {
      navigate(
        `/answer-morning-form?pageId=${certificationId}&seazon=${seazonId}&level=MORNING1`
      );
    } else {
      navigate(
        `/answer-morning-form?pageId=${certificationId}&seazon=${seazonId}`
      );
    }
  };
  return (
    <div className={styles.container}>
      {/* いらない気がしたが一応コメントで残す */}
      {/* <div className={styles.titleContainer}>
        <div>
          みんなの解答結果
          {props.examlevel === ANSWER_MORNING1 ? (
            <span>(午前I)</span>
          ) : certificationId !== ANSWER_MORNING_EIGHTY ? (
            <span>(午前Ⅱ)</span>
          ) : (
            <span>(午前)</span>
          )}
        </div>
      </div> */}
      <div className={styles.answerButton}>
        <SimpleButton
          label={"回答する"}
          width="40%"
          color="orange"
          onClick={answer}
        ></SimpleButton>
      </div>
      <div className={styles.answerArea}>
        <div className={styles.tableContainer}>
          <table className={styles.table} border={1}>
            <thead>
              <tr>
                <th>問題</th>
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D</th>
                <th>信頼度</th>
              </tr>
            </thead>
            <tbody>
              {displayAnswers &&
                displayAnswers.map((answer, index) => (
                  <tr key={index}>
                    <td className={styles.probremNo}>問{answer.no}</td>
                    <td style={createCellStyle(answer, answer.answerA)}>
                      {answer.answerA}
                    </td>
                    <td style={createCellStyle(answer, answer.answerB)}>
                      {answer.answerB}
                    </td>
                    <td style={createCellStyle(answer, answer.answerC)}>
                      {answer.answerC}
                    </td>
                    <td style={createCellStyle(answer, answer.answerD)}>
                      {answer.answerD}
                    </td>
                    <td
                      className={styles.reliability}
                      style={reliabilityStyle(answer)}
                    >
                      {answer.reliability}%
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AnswerMorningList;
