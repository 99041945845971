// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AnswerMorningForm_formWrapper__\\+GdHI {
  display: flex;
  justify-content: center;
  padding: 0 1.2rem;
  flex-direction: column;
}

.AnswerMorningForm_formParts__ePEKl {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.AnswerMorningForm_answerTitile__5BT8l {
  margin-top: 20px;
  padding: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
}

.AnswerMorningForm_submit__LTzdf {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.AnswerMorningForm_submitButton__Vedez {
  width: 50%;
}

.AnswerMorningForm_answerContainer__rp5OZ {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  padding-bottom: 10px;
}

.AnswerMorningForm_labelArea__w2fFR {
  display: flex;
  align-items: center;
}

.AnswerMorningForm_checkIcon__8wpq3 {
  margin-left: 0.2rem;
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/organism/answer-morning-form/AnswerMorningForm.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,iBAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,UAAA;AACF","sourcesContent":[".formWrapper {\n  display: flex;\n  justify-content: center;\n  padding: 0 1.2rem;\n  flex-direction: column;\n}\n\n.formParts {\n  display: flex;\n  justify-content: center;\n  margin-top: 1rem;\n}\n\n.answerTitile {\n  margin-top: 20px;\n  padding: 10px;\n  text-align: center;\n  font-size: 18px;\n  font-weight: 800;\n}\n\n.submit {\n  padding: 10px 0;\n  display: flex;\n  justify-content: center;\n}\n\n.submitButton {\n  width: 50%;\n}\n\n.answerContainer {\n  margin-top: 20px;\n  text-align: center;\n  font-size: 14px;\n  padding-bottom: 10px;\n}\n\n.labelArea {\n  display: flex;\n  align-items: center;\n}\n\n.checkIcon {\n  margin-left: 0.2rem;\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formWrapper": `AnswerMorningForm_formWrapper__+GdHI`,
	"formParts": `AnswerMorningForm_formParts__ePEKl`,
	"answerTitile": `AnswerMorningForm_answerTitile__5BT8l`,
	"submit": `AnswerMorningForm_submit__LTzdf`,
	"submitButton": `AnswerMorningForm_submitButton__Vedez`,
	"answerContainer": `AnswerMorningForm_answerContainer__rp5OZ`,
	"labelArea": `AnswerMorningForm_labelArea__w2fFR`,
	"checkIcon": `AnswerMorningForm_checkIcon__8wpq3`
};
export default ___CSS_LOADER_EXPORT___;
