import SendIcon from "@mui/icons-material/Send";
import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ANSWER_MORNING1,
  ANSWER_MORNING_EIGHTY,
} from "../../../constant/const";
import RadioButton, { RadioResult } from "../../atoms/radio-button/RadioButton";
import { PostAnswerMorningContext } from "../../hooks/reducer/AnswerMorningReducer";
import styles from "./AnswerMorningForm.module.scss";
import SimpleModal from "../../organism/simple-modal/SimpleModal";
import { useAtom } from "jotai";
import { modalState } from "../../molecules/modal-wrapper/ModalWrapper";
import SimpleButton from "../../atoms/button/SimpleButton";
interface AnswerMorningProps {
  examlevel: "MORNING1" | "MORNING2";
}

const AnswerMorningForm = (props: AnswerMorningProps) => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const navigate = useNavigate();
  const certificationId = query?.get("pageId");
  const seazonId = query?.get("seazon");
  const [answerForm, setAnswerForm] = useState<(string | null)[]>([]);
  const [, setState] = useAtom(modalState);

  useEffect(() => {
    window.scrollTo(0, 0);

    setAnswerForm(
      props.examlevel === ANSWER_MORNING1
        ? [...Array<string | null>(30).fill(null)]
        : certificationId === ANSWER_MORNING_EIGHTY
        ? [...Array<string | null>(80).fill(null)]
        : [...Array<string | null>(25).fill(null)]
    );
  }, [props]);

  const postAnswerMorningContext = useContext(PostAnswerMorningContext);
  useEffect(() => {
    if (postAnswerMorningContext.state.status === "success") {
      setState(true);
      postAnswerMorningContext?.dispatch?.({
        type: "processing",
        response: null,
        error: null,
      });
    }
  }, [postAnswerMorningContext.state]);

  // ラジオボタンが変更されたときのハンドラ
  const handleChange = (e: RadioResult, index: number) => {
    const newAnswers = [...answerForm];
    newAnswers[index] = e.value;
    setAnswerForm(newAnswers);
  };

  const submit = () => {
    if (certificationId == null || seazonId == null) {
      return;
    }
    const ans = answerForm.map((ans, index) => {
      return {
        no: index + 1,
        // よくない
        answer: ans as string,
      };
    });
    const pageId =
      props.examlevel === ANSWER_MORNING1 ? "MORNING1" : certificationId;

    const requestAnswers = {
      pageId: pageId,
      seazon: seazonId,
      answers: ans,
    };
    postAnswerMorningContext?.post?.(requestAnswers);
  };
  const handleClose = () => {
    if (props.examlevel === ANSWER_MORNING1) {
      navigate(
        `/answer-morning-list?pageId=${certificationId}&seazon=${seazonId}&level=MORNING1`
      );
    } else {
      navigate(
        `/answer-morning-list?pageId=${certificationId}&seazon=${seazonId}`
      );
    }
  };

  return (
    <div>
      <div className={styles.answerTitile}>
        解答フォーム
        {props.examlevel === ANSWER_MORNING1 ? (
          <span>(午前I)</span>
        ) : certificationId !== ANSWER_MORNING_EIGHTY ? (
          <span>(午前Ⅱ)</span>
        ) : (
          <span>(午前)</span>
        )}
      </div>
      <div className={styles.formWrapper}>
        {answerForm.map((key, index) => (
          <div className={styles.formParts} key={index}>
            <RadioButton
              name="answer"
              id={`answer-${index}`}
              value={key}
              items={[
                {
                  display: "A",
                  value: "A",
                },
                {
                  display: "B",
                  value: "B",
                },
                {
                  display: "C",
                  value: "C",
                },
                {
                  display: "D",
                  value: "D",
                },
              ]}
              label={`問${index + 1}`}
              onChange={(e) => handleChange(e, index)}
            ></RadioButton>
          </div>
        ))}
      </div>
      <div className={styles.answerContainer}>
        <div className={styles.submit}>
          <SimpleButton
            label={"送信"}
            width="50%"
            color="orange"
            disabled={answerForm.includes(null)}
            onClick={() => submit()}
          ></SimpleButton>
          {/* <Button
            variant="contained"
            endIcon={<SendIcon />}
            className={styles.submitButton}
            color="success"
            disabled={answerForm.includes(null)}
            onClick={() => submit()}
          >
            送信
          </Button> */}
          <SimpleModal
            handleClose={handleClose}
            message="回答が完了しました。<br/>午前試験お疲れ様でした。"
          ></SimpleModal>
        </div>
      </div>
    </div>
  );
};

export default AnswerMorningForm;
