import React, { useEffect, useRef, useState } from "react";
import styles from "./CommentPopup.module.scss";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Comment } from "../../api/CommentApi";
import { toHHMM, toYYYYMMDDWithSlah } from "../../utils/date-util";
import SendIcon from "@mui/icons-material/Send";

type CommentPopupProps = {
  comments: Comment[] | null;
  submitComment: (content: string) => void;
  setIsOpenComment: (flag: boolean) => void;
};

const CommentPopup = (props: CommentPopupProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [disable, setDisable] = useState(true);
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    const element = textAreaRef.current;
    if (!element) {
      return;
    }

    element.style.height = "auto";
    element.style.height = `calc(${element.scrollHeight}px - 10px)`;
  }, [content]);

  const changeForm = (event: any) => {
    setContent(event.target.value);
    setDisable(event.target.value.trim("\n").length === 0);
  };

  const submit = () => {
    props.submitComment(content);
    setContent("");
    setDisable(true);
  };

  return (
    <div className={styles.overlay}>
      <div className={`${styles.commentArea} ${styles.slideUp}`}>
        <div className={styles.upperArea}>
          <div className={styles.commentTop}>
            <div className={styles.title}>コメント</div>
            <div className={styles.closeIcon}>
              <CloseSharpIcon
                sx={{ fontSize: "1.4rem", color: "white" }}
                onClick={() => props.setIsOpenComment(false)}
              />
            </div>
          </div>
          <div className={styles.commentMain}>
            {props.comments != null && props.comments.length != 0 ? (
              props.comments.map((comment, index) => (
                <div
                  className={styles.commentInnerTop}
                  key={`${comment._id}-${index}`}
                >
                  <div className={styles.commentInfo}>
                    <div>{comment._id.slice(-5)}</div>
                    <div>{`${toYYYYMMDDWithSlah(comment.createTime)} ${toHHMM(
                      comment.createTime
                    )}`}</div>
                  </div>
                  <div className={styles.comment}>{comment.comment}</div>
                </div>
              ))
            ) : (
              <div className={styles.noPost}>コメントはありません</div>
            )}
          </div>
        </div>
        <div className={styles.bottomArea}>
          {/* TODO: コンポーネント化 */}
          <div className={styles.submitWrapper}>
            <textarea
              ref={textAreaRef}
              rows={1}
              onChange={changeForm}
              value={content}
              className={styles.input}
            />
            <div className={styles.submit}>
              {disable ? (
                <SendIcon sx={{ fontSize: "1.5rem", color: "grey" }} />
              ) : (
                <SendIcon
                  sx={{ fontSize: "1.5rem", color: "rgb(91 153 237)" }}
                  onClick={submit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentPopup;
