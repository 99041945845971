// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatLayout_messageWrapper__5yoPl {
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  height: calc(100% - 4rem);
}`, "",{"version":3,"sources":["webpack://./src/components/organism/chat-layout/ChatLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,sBAAA;EACA,aAAA;EACA,yBAAA;AACF","sourcesContent":[".messageWrapper {\n  box-sizing: border-box;\n  flex-direction: column;\n  display: flex;\n  height: calc(100% - 4rem);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageWrapper": `ChatLayout_messageWrapper__5yoPl`
};
export default ___CSS_LOADER_EXPORT___;
