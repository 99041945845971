import { BrowserRouter } from "react-router-dom";
import Router from "./router/router";
import Loading, {
  LoadingProvider,
} from "./components/molecules/loading/Loading";
import { AppProvider } from "./components/hooks/provider/AppProvider";
import styles from "./App.module.scss";
import ErrorHandler, {
  ErrorHandlerProvider,
} from "./components/molecules/error-handler/ErrorHandler";
import ModalWrapper, {
  modalState,
} from "./components/molecules/modal-wrapper/ModalWrapper";
import { useAtom } from "jotai";

function App() {
  return (
    <BrowserRouter>
      <LoadingProvider>
        <Loading />
        <ErrorHandlerProvider>
          <ErrorHandler />
          <div className={styles.appContainer}>
            <AppProvider>
              <Router></Router>
            </AppProvider>
          </div>
        </ErrorHandlerProvider>
      </LoadingProvider>
    </BrowserRouter>
  );
}

export default App;
