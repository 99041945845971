// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_appContainer__cXP6h {
  background: #ffffff;
  position: relative;
  margin: auto;
  height: 100dvh;
  overflow: auto;
  line-height: 1.6;
}`, "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EAEA,YAAA;EACA,cAAA;EACA,cAAA;EACA,gBAAA;AAAF","sourcesContent":[".appContainer {\n  background: #ffffff;\n  position: relative;\n  // max-width: 800px;\n  margin: auto;\n  height: 100dvh;\n  overflow: auto;\n  line-height: 1.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appContainer": `App_appContainer__cXP6h`
};
export default ___CSS_LOADER_EXPORT___;
