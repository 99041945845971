// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorHandler_container__tj6CF {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10000000000;
  color: white;
}
.ErrorHandler_container__tj6CF .ErrorHandler_messageContainer__YXjan {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.ErrorHandler_container__tj6CF .ErrorHandler_messageContainer__YXjan .ErrorHandler_message__ED-Ia {
  font-size: 1.2rem;
  font-weight: 800;
}
.ErrorHandler_container__tj6CF .ErrorHandler_submitButton__atsM8 {
  margin-top: 6rem;
  width: 50%;
  max-width: 20rem;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/error-handler/ErrorHandler.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,SAAA;EACA,6BAAA;EACA,oBAAA;EACA,YAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AAEJ;AADI;EACE,iBAAA;EACA,gBAAA;AAGN;AAAE;EACE,gBAAA;EACA,UAAA;EACA,gBAAA;AAEJ","sourcesContent":[".container {\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  background: rgba(0, 0, 0, 0.8);\n  text-align: center;\n  left: 50%;\n  transform: translate(-50%, 0);\n  z-index: 10000000000;\n  color: white;\n  .messageContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    .message {\n      font-size: 1.2rem;\n      font-weight: 800;\n    }\n  }\n  .submitButton {\n    margin-top: 6rem;\n    width: 50%;\n    max-width: 20rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ErrorHandler_container__tj6CF`,
	"messageContainer": `ErrorHandler_messageContainer__YXjan`,
	"message": `ErrorHandler_message__ED-Ia`,
	"submitButton": `ErrorHandler_submitButton__atsM8`
};
export default ___CSS_LOADER_EXPORT___;
