// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RadioButton_label__TJi0S {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.RadioButton_inputContainer__Iinne {
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  border: 1px solid #d8d8d8;
}
.RadioButton_inputContainer__Iinne .RadioButton_inputArea__6PuVy {
  padding: 1rem 1.2rem;
  font-size: 1rem;
  width: 2.5rem;
}
.RadioButton_inputContainer__Iinne .RadioButton_inputArea__6PuVy .RadioButton_input__ZxkWe {
  accent-color: #e83018;
}
.RadioButton_inputContainer__Iinne .RadioButton_inputArea__6PuVy .RadioButton_inputLabel__j6IbQ {
  display: inline-block;
  width: calc(100% - 25rem);
  margin-left: 0.1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/radio-button/RadioButton.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;AACF;;AACA;EACE,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,yBAAA;AAEF;AADE;EACE,oBAAA;EACA,eAAA;EACA,aAAA;AAGJ;AAFI;EACE,qBAAA;AAIN;AAFI;EACE,qBAAA;EACA,yBAAA;EACA,mBAAA;AAIN","sourcesContent":[".label {\n  font-size: 1rem;\n  margin-bottom: 1rem;\n}\n.inputContainer {\n  background: white;\n  border-radius: 1rem;\n  overflow: hidden;\n  display: flex;\n  border: 1px solid #d8d8d8;\n  .inputArea {\n    padding: 1rem 1.2rem;\n    font-size: 1rem;\n    width: 2.5rem;\n    .input {\n      accent-color: #e83018;\n    }\n    .inputLabel {\n      display: inline-block;\n      width: calc(100% - 25rem);\n      margin-left: 0.1rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `RadioButton_label__TJi0S`,
	"inputContainer": `RadioButton_inputContainer__Iinne`,
	"inputArea": `RadioButton_inputArea__6PuVy`,
	"input": `RadioButton_input__ZxkWe`,
	"inputLabel": `RadioButton_inputLabel__j6IbQ`
};
export default ___CSS_LOADER_EXPORT___;
