import {
  Context,
  Dispatch,
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { Action, State, createInitialState } from "./state";
import {
  ChangeLoadingState,
  LoadingContext,
} from "../../molecules/loading/Loading";
import axios from "axios";
import { ReactNode } from "react";
import {
  ChangeErrorHandlerState,
  ErrorHandlerContext,
} from "../../molecules/error-handler/ErrorHandler";

export type GetReportResponse = {
  _id: string;
  resultId: string;
  studyPeriod: string;
  studyMethod: string;
  comment: string;
  postTime: string;
  seazonName: string;
  morning1Score: number;
  morning2Score: number;
  afternoon1Score: number;
  afternoon2Score: number;
};

type ContextType<T> = {
  state: State<T>;
  dispatch?: Dispatch<Action<T>>;
  refetch?: (params: GetReportParams) => State<T>;
  post?: (request: T) => State<T>;
};

const reducer = (
  state: State<GetReportResponse>,
  action: Action<GetReportResponse>
): State<GetReportResponse> => {
  switch (action.type) {
    case "processing":
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case "success":
      return {
        status: action.type,
        data: action.response,
        error: null,
      };
    case "error":
      return {
        status: action.type,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
};

const postReducer = (
  state: State<PostReportRequest>,
  action: Action<PostReportRequest>
): State<PostReportRequest> => {
  switch (action.type) {
    case "processing":
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case "success":
      return {
        status: action.type,
        data: action.response,
        error: null,
      };
    case "error":
      return {
        status: action.type,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const initialState = createInitialState<GetReportResponse>();

export const GetReportContext = createContext<ContextType<GetReportResponse>>({
  state: initialState,
});

export const initialPostState = createInitialState<PostReportRequest>();

export const PostReportContext = createContext<ContextType<PostReportRequest>>({
  state: initialPostState,
});

export type GetReportParams = {
  pageId: string | null;
};

export type PostReportRequest = {
  // _id: string;
  pageId: string | null;
  resultId: string | null;
  studyPeriod: string | null;
  studyMethod: string | null;
  comment: string | null;
  seazonName: string | null;
  morning1Score: number | null;
  morning2Score: number | null;
  afternoon1Score: number | null;
  afternoon2Score: number | null;
};

export const getReport = (
  context: ContextType<GetReportResponse>,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void,
  params: GetReportParams
) => {
  const url = process.env.REACT_APP_SERVER_PATH + "/report";

  if (context.dispatch == null) {
    return context.state;
  }

  //   changeLoadingState(true);
  context.dispatch({ type: "processing", response: null, error: null });

  (async () => {
    try {
      const response = await axios.get(url, {
        params,
      });
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: "success",
        response: response.data,
        error: null,
      });
      //   changeLoadingState(false);
    } catch (error) {
      // changeLoadingState(false);
      handleError(true, error);
    }
  })();
  return context.state;
};

export function FetchReportProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const refetch = useCallback(
    (params: GetReportParams) =>
      getReport({ state, dispatch }, changeLoadingState, handleError, params),
    []
  );

  return (
    <GetReportContext.Provider value={{ state, dispatch, refetch }}>
      {children}
    </GetReportContext.Provider>
  );
}

export const postReport = (
  context: ContextType<PostReportRequest>,
  params: PostReportRequest,
  handleError: (hasError: boolean, error?: unknown) => void
) => {
  const url = process.env.REACT_APP_SERVER_PATH + "/report";

  if (context.dispatch == null) {
    return context.state;
  }

  context.dispatch({ type: "processing", response: null, error: null });

  (async () => {
    try {
      const response = await axios.post(url, {
        pageId: params.pageId,
        comment: params.comment,
        resultId: params.resultId,
        studyPeriod: params.studyPeriod,
        studyMethod: params.studyMethod,
        seazonName: params.seazonName,
        morning1Score: params.morning1Score,
        morning2Score: params.morning2Score,
        afternoon1Score: params.afternoon1Score,
        afternoon2Score: params.afternoon2Score,
      });
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: "success",
        response: response.data,
        error: null,
      });
    } catch (error) {
      handleError(true, error);
    }
  })();
  return context.state;
};

export function FetchPostReportProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(postReducer, initialPostState);
  const { handleError } = useContext(ErrorHandlerContext);

  const post = useCallback(
    (params: PostReportRequest) =>
      postReport({ state, dispatch }, params, handleError),
    []
  );

  return (
    <PostReportContext.Provider value={{ state, dispatch, post }}>
      {children}
    </PostReportContext.Provider>
  );
}
