import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { useAtom } from "jotai";
import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AFTERNOON_UNDIVIDED,
  ANSWER_MORNING_EIGHTY,
} from "../../../constant/const";
import { MetaData, metaAnswerAfternoonForHelmet } from "../../../constant/meta";
import Pulldown from "../../atoms/pulldown/Pulldown";
import { TextFormResult } from "../../atoms/textbox/Textbox";
import {
  FetchAnswerAfternoonContext,
  FetchAnswerAfternoonResponse,
} from "../../hooks/reducer/AnswerAfternoonReducer";
import { modalState } from "../../molecules/modal-wrapper/ModalWrapper";
import {
  filterQuestion,
  ouyouSection,
  sekisupeSection,
  section,
  question,
} from "../../organism/answer-afternoon-form/AnswerAfternoon-const";
import AnswerDeleteModal from "../../organism/answer-delete-modal/AnswerDeleteModal";
import HeaderLayout from "../../organism/header-layout/HeaderLayout";
import { toHHMM, toYYYYMMDDWithSlah } from "../../utils/date-util";
import { createTitle } from "../../utils/header-util";
import styles from "./AnswerAfternoonList.module.scss";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Comment, useFetchComment, usePostComment } from "../../api/CommentApi";
import { Like, usePostLike } from "../../api/LikeApi";
import SimpleButton from "../../atoms/button/SimpleButton";
import CommentPopup from "../../organism/comment-popup/CommentPopup";
import { useCertification } from "../../hooks/custom-hooks/useCertification";
import { useHelmetHeader } from "../../hooks/custom-hooks/useHelmetHeader";

type PulldownValue = {
  section: string;
  question: string;
};

type DisplayedAfternoonAnswer = FetchAnswerAfternoonResponse & {
  liked: boolean;
};

const AnswerAfternoonList = () => {
  const [examStage, setExamStage] = useState<string>("afternoon1");
  const [defaultAfternoonAnswer, setDefaultAfternoonAnswers] = useState<
    DisplayedAfternoonAnswer[] | null
  >(null);
  const [displayedAfternoonAnswers, setDisplayedAfternoonAnswers] = useState<
    DisplayedAfternoonAnswer[] | null
  >(null);
  const [selectId, setSelectId] = useState<string | null>(null);
  const navigate = useNavigate();
  const { metaProp } = useHelmetHeader();

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const certificationId = query?.get("pageId");
  const seazonId = query?.get("seazon");

  const fetchAnswerAfternoonContext = useContext(FetchAnswerAfternoonContext);
  const [state, setState] = useAtom(modalState);
  const [isOpenComment, setIsOpenComment] = useState<boolean>(false);
  const [selectedAnswerId, setSelectedAnswerId] = useState<string | null>(null);
  const [comments, setComments] = useState<Comment[] | null>(null);

  const initialPulldownValue: PulldownValue = {
    section: "all",
    question: "all",
  };

  const [pulldownValue, setPulldownValue] = useState(initialPulldownValue);
  const postCommentApi = usePostComment();
  const fetchCommentApi = useFetchComment();
  const postLikeApi = usePostLike();

  const { certifications } = useCertification();
  const targetCertification = certifications?.filter(
    (c) => c.certificationId === certificationId
  )[0];

  useEffect(() => {
    if (certificationId == null || seazonId == null) {
      return;
    }
    const params = {
      certificationId,
      seazonId,
      examStage, // 名前を見直したい
    };

    if (!state) {
      fetchAnswerAfternoonContext?.refetch?.(params);
    }
  }, []);

  useEffect(() => {
    if (certificationId == null || seazonId == null) {
      return;
    }
    const params = {
      certificationId,
      seazonId,
      examStage, // 名前を見直したい
    };

    if (!state) {
      fetchAnswerAfternoonContext?.refetch?.(params);
    }
  }, [examStage, state, fetchCommentApi.state?.data]);

  useEffect(() => {
    if (fetchAnswerAfternoonContext.state.data == null) {
      return;
    }

    const likedAnswers = getLocalStorage();

    const displayedAfternoonAnswers =
      fetchAnswerAfternoonContext.state.data?.map((answer) => {
        return {
          ...answer,
          liked: likedAnswers.includes(answer.id),
        };
      });
    setDefaultAfternoonAnswers(displayedAfternoonAnswers);
    setDisplayedAfternoonAnswers(displayedAfternoonAnswers);
  }, [fetchAnswerAfternoonContext.state.data]);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    tabKey: string
  ) => {
    setExamStage(tabKey);
  };

  const answer = () => {
    navigate(
      `/answer-afternoon-form?pageId=${certificationId}&seazon=${seazonId}`
    );
  };

  useEffect(() => {
    if (defaultAfternoonAnswer == null) return;

    applyFilter(defaultAfternoonAnswer, pulldownValue);
  }, [examStage, pulldownValue]);

  const openModal = (answer: DisplayedAfternoonAnswer) => {
    setSelectId(answer.id);
    setState(true);
  };
  const applyFilter = (
    afternoonAnswers: DisplayedAfternoonAnswer[] | null,
    criteria: PulldownValue
  ) => {
    // フィルタリングされた回答を格納する変数
    const selectedAnswer = afternoonAnswers?.filter((a) => {
      // 大問が "all" または大問のフィルタ条件で抽出
      const sectionMatch =
        criteria.section === "all" || a.section === criteria.section;
      // 質問が "all" または質問のフィルタ条件で抽出
      const questionMatch =
        criteria.question === "all" || a.question === criteria.question;
      // 両方の条件を満たすものを抽出
      return sectionMatch && questionMatch;
    });

    setDisplayedAfternoonAnswers(selectedAnswer ?? afternoonAnswers);
  };
  const filterSection = () => {
    const selectedSection =
      certificationId === ANSWER_MORNING_EIGHTY
        ? ouyouSection
        : certificationId === "SEKISUPE"
        ? sekisupeSection
        : section;

    return [{ name: "全て", value: "all" }, ...selectedSection];
  };

  const selectedSection = () => {
    if (certificationId === ANSWER_MORNING_EIGHTY) {
      return ouyouSection;
    } else if (certificationId === "SEKISUPE") {
      return sekisupeSection;
    }

    return section;
  };

  const handleForm = (e: TextFormResult) => {
    setPulldownValue((prevState) => {
      const newState = {
        ...prevState,
        [e.name]: e.value,
      };

      return newState;
    });
  };

  const changeLike = async (afternoonAnswer: DisplayedAfternoonAnswer) => {
    // 今のいいね状態
    const isLike = afternoonAnswer.liked;
    await postLikeApi.post({
      afternoonAnswerId: afternoonAnswer.id,
      liked: !isLike, // いいねしてたら解除、してなかったら追加
    });

    // localStorage更新

    const likedAnswers = getLocalStorage();
    // answerIdが既に存在する場合は削除し、存在しない場合は追加
    if (likedAnswers.includes(afternoonAnswer.id)) {
      const index = likedAnswers.indexOf(afternoonAnswer.id);
      likedAnswers.splice(index, 1);
    } else {
      likedAnswers.push(afternoonAnswer.id);
    }

    // 更新されたlikedAnswersをlocalStorageに保存
    localStorage.setItem("likedAnswers", JSON.stringify(likedAnswers));

    const updatedAfternoonAnswers = displayedAfternoonAnswers?.map((answer) => {
      if (answer.id === afternoonAnswer.id) {
        return {
          ...answer,
          liked: !isLike,
          likeNum: isLike ? answer.likeNum - 1 : answer.likeNum + 1,
        };
      }
      return answer;
    });

    setDisplayedAfternoonAnswers(
      updatedAfternoonAnswers ?? defaultAfternoonAnswer
    );
  };

  const getLocalStorage = (): string[] => {
    const storedLikedAnswers = localStorage.getItem("likedAnswers");

    if (storedLikedAnswers == null) {
      return [];
    }

    return JSON.parse(storedLikedAnswers);
  };

  const openComment = async (answerId: string) => {
    setIsOpenComment(true);
    setSelectedAnswerId(answerId);
    const response = await fetchCommentApi.fetch({
      afternoonAnswerId: answerId,
    });

    setComments(response);
  };

  const submitComment = async (content: string) => {
    if (selectedAnswerId == null) {
      return;
    }

    await postCommentApi.post({
      afternoonAnswerId: selectedAnswerId,
      comment: content,
    });

    const response = await fetchCommentApi.fetch({
      afternoonAnswerId: selectedAnswerId,
    });

    setComments(response);
  };

  return (
    <div className={styles.container}>
      {metaProp && (
        <Helmet
          title={metaProp.title}
          meta={[{ name: "description", content: metaProp.description }]}
        />
      )}
      <HeaderLayout
        title={createTitle(targetCertification ?? null, seazonId)}
        backgroundColor="#FFFFFF"
        color="#333"
        textAlign="center"
        isBackIcon={true}
        isMenu={true}
      >
        <div className={styles.titleContainer}>
          {/* いらない気がしたが一応コメントで残す */}
          {/* <div>みんなの解答結果(午後)</div> */}
          <div className={styles.innerTitleContainer}>
            <SimpleButton
              label={"回答する"}
              width="40%"
              color="orange"
              onClick={() => answer()}
            ></SimpleButton>
          </div>
          <div className={styles.filterArea}>
            <div className={styles.title}>フィルタ</div>
            <Pulldown
              id={"section"}
              name={"section"}
              title="大問"
              items={filterSection()}
              selectedValue={pulldownValue.section}
              handlePulldownChange={handleForm}
            ></Pulldown>
            <Pulldown
              id={"question"}
              name={"question"}
              title="設問"
              items={filterQuestion}
              selectedValue={pulldownValue.question}
              handlePulldownChange={handleForm}
            ></Pulldown>
          </div>
          {/* tabを表示するのは応用とせきスペ以外(20240308時点) */}
          {certificationId &&
            !AFTERNOON_UNDIVIDED.includes(certificationId) && (
              <div className={styles.tabArea}>
                <TabContext value={examStage}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} className={styles.tabList}>
                      <Tab
                        label="午後Ⅰ"
                        value={"afternoon1"}
                        className={styles.tab}
                      />
                      <Tab
                        label="午後Ⅱ"
                        value={"afternoon2"}
                        className={styles.tab}
                      />
                    </TabList>
                  </Box>
                </TabContext>
              </div>
            )}
        </div>
        <div className={styles.content}>
          {displayedAfternoonAnswers?.length === 0 && (
            <div className={styles.noPost}>
              <div>投稿がありません</div>
            </div>
          )}
          {displayedAfternoonAnswers?.map((afternoonAnswer, index) => (
            <div className={styles.answerContainer} key={index}>
              <div className={styles.upperArea}>
                <div className={styles.title}>
                  <div className={styles.userArea}>
                    <div className={styles.userNameArea}>
                      <Avatar
                        sx={{
                          width: 32,
                          height: 32,
                          fontSize: 16,
                          lineHeight: 32,
                        }}
                      ></Avatar>
                      <div className={styles.userName}>
                        {afternoonAnswer.userName}
                      </div>
                    </div>
                    <div onClick={() => openModal(afternoonAnswer)}>
                      <DeleteForeverIcon sx={{ fontSize: 32 }} />
                    </div>
                  </div>
                  <div className={styles.answerTitle}>
                    <div className={styles.parts}>
                      {
                        selectedSection().find(
                          (section: { name: string; value: string }) =>
                            section.value === afternoonAnswer.section
                        )?.name
                      }
                    </div>
                  </div>
                  <div className={styles.questionArea}>
                    <div className={styles.question}>
                      {
                        question.find(
                          (question) =>
                            question.value === afternoonAnswer.question
                        )?.name
                      }
                    </div>
                    <div className={styles.postTimeArea}>
                      <div>
                        {toYYYYMMDDWithSlah(afternoonAnswer.createDate)}
                      </div>
                      <div>{toHHMM(afternoonAnswer.createDate)}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.answer}>{afternoonAnswer.answer}</div>
              </div>
              <div className={styles.iconArea}>
                <div
                  className={styles.likeIcon}
                  onClick={() => changeLike(afternoonAnswer)}
                >
                  {afternoonAnswer.liked ? (
                    <FavoriteIcon sx={{ color: "#e600ff" }} />
                  ) : (
                    <FavoriteBorderIcon sx={{ color: "grey" }} />
                  )}
                </div>
                <div className={styles.likeNum}>{afternoonAnswer.likeNum}</div>
                <div
                  className={styles.commentIcon}
                  onClick={() => openComment(afternoonAnswer.id)}
                >
                  <ModeCommentOutlinedIcon className={styles.comment} />
                </div>
                <div className={styles.commentNum}>
                  {afternoonAnswer.commentNum}
                </div>
              </div>
            </div>
          ))}
        </div>
      </HeaderLayout>
      <AnswerDeleteModal id={selectId ?? ""}></AnswerDeleteModal>
      {isOpenComment && (
        <CommentPopup
          comments={comments}
          setIsOpenComment={setIsOpenComment}
          submitComment={submitComment}
        ></CommentPopup>
      )}
    </div>
  );
};

export default AnswerAfternoonList;
