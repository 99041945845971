import { atom, useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ErrorHandler.module.scss";
import { Button } from "@mui/material";

export const errorAtom = atom<any>(null);

const ErrorHandlerPage = () => {
  const [state] = useAtom(errorAtom);
  const [messages, setMessages] = useState<string[] | null>(null);

  const systemErrorMessages = [
    "エラーが発生しました。",
    "再度処理を実行してください。",
  ];

  useEffect(() => {
    if (state == null) {
      return;
    }
    if (state instanceof AxiosError) {
      const messages = state.response?.data?.error;
      setMessages(
        messages == null || messages.length === 0
          ? systemErrorMessages
          : messages
      );
    } else if (
      Array.isArray(state) &&
      state.length > 0 &&
      typeof state[0] === "string"
    ) {
      setMessages(state);
    } else {
      setMessages(systemErrorMessages);
    }
  }, [state]);

  const close = () => {
    setMessages(null);
  };

  return (
    <>
      {messages && (
        <div className={styles.container}>
          <div className={styles.messageContainer}>
            <div className={styles.messageArea}>
              {messages.map((m, i) => (
                <div className={styles.message} key={i}>
                  {m}
                </div>
              ))}
            </div>
            <Button
              variant="contained"
              className={styles.submitButton}
              color="error"
              onClick={close}
            >
              閉じる
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorHandlerPage;
