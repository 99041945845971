import {
  Dispatch,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { Action, State, createInitialState } from "./state";
import axios from "axios";
import {
  ChangeErrorHandlerState,
  ErrorHandlerContext,
} from "../../molecules/error-handler/ErrorHandler";

export type PostAnswerAfternoon = {
  certificationId: string | null;
  seazonId: string | null;
  examStage: string | null;
  section: string | null;
  answer: string | null;
  difficulty: string | null;
  password: string | null;
  userName: string | null;
};

type ContextType<T> = {
  state: State<T>;
  dispatch?: Dispatch<Action<T>>;
  refetch?: (params: FetchAnswerAfternoonParams) => State<T>;
  post?: (request: T) => State<T>;
  deleteAnswer?: (request: T) => void;
};

const postReducer = (
  state: State<PostAnswerAfternoon>,
  action: Action<PostAnswerAfternoon>
): State<PostAnswerAfternoon> => {
  switch (action.type) {
    case "processing":
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case "success":
      return {
        status: action.type,
        data: action.response,
        error: null,
      };
    case "error":
      return {
        status: action.type,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const initialPostState = createInitialState<PostAnswerAfternoon>();

export const PostAnswerAfternoonContext = createContext<
  ContextType<PostAnswerAfternoon>
>({
  state: initialPostState,
});

export const PostAnswerAfternoon = (
  context: ContextType<PostAnswerAfternoon>,
  params: PostAnswerAfternoon,
  handleError: (hasError: boolean, error?: unknown) => void
) => {
  const url = process.env.REACT_APP_SERVER_PATH + "/answerAfternoon";

  if (context.dispatch == null) {
    return context.state;
  }

  context.dispatch({ type: "processing", response: null, error: null });

  (async () => {
    try {
      const response = await axios.post(url, {
        userName: params.userName,
        password: params.password,
        examStage: params.examStage,
        section: params.section,
        answer: params.answer,
        difficulty: params.difficulty,
        certificationId: params.certificationId,
        seazonId: params.seazonId,
      });
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: "success",
        response: response.data,
        error: null,
      });
    } catch (error) {
      handleError(true, error);
    }
  })();
  return context.state;
};

export function PostAnswerAfternoonProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer(postReducer, initialPostState);
  const { handleError } = useContext(ErrorHandlerContext);

  const post = useCallback(
    (params: PostAnswerAfternoon) =>
      PostAnswerAfternoon({ state, dispatch }, params, handleError),
    []
  );

  return (
    <PostAnswerAfternoonContext.Provider value={{ state, dispatch, post }}>
      {children}
    </PostAnswerAfternoonContext.Provider>
  );
}

type FetchAnswerAfternoonParams = {
  certificationId: string;
  seazonId: string;
  examStage: string;
};

export type FetchAnswerAfternoonResponse = {
  id: string;
  examStage: string;
  section: string;
  answer: string;
  difficulty: string;
  userName: string;
  createDate: string;
};

const fetchResucer = (
  state: State<FetchAnswerAfternoonResponse>,
  action: Action<FetchAnswerAfternoonResponse>
): State<FetchAnswerAfternoonResponse> => {
  switch (action.type) {
    case "processing":
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case "success":
      return {
        status: action.type,
        data: action.response,
        error: null,
      };
    case "error":
      return {
        status: action.type,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const initialFetchState =
  createInitialState<FetchAnswerAfternoonResponse>();

export const FetchAnswerAfternoonContext = createContext<
  ContextType<FetchAnswerAfternoonResponse>
>({
  state: initialFetchState,
});

export const fetchAnswerAfternoon = (
  context: ContextType<FetchAnswerAfternoonResponse>,
  params: FetchAnswerAfternoonParams,
  handleError: (hasError: boolean, error?: unknown) => void
) => {
  const url = process.env.REACT_APP_SERVER_PATH + "/answerAfternoon";

  if (context.dispatch == null) {
    return context.state;
  }

  context.dispatch({ type: "processing", response: null, error: null });

  (async () => {
    try {
      const response = await axios.get(url, {
        params,
      });
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: "success",
        response: response.data,
        error: null,
      });
    } catch (error) {
      handleError(true, error);
    }
  })();
  return context.state;
};

export function FetchAnswerAfternoonProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer(fetchResucer, initialFetchState);
  const { handleError } = useContext(ErrorHandlerContext);

  const refetch = useCallback(
    (params: FetchAnswerAfternoonParams) =>
      fetchAnswerAfternoon({ state, dispatch }, params, handleError),
    []
  );

  return (
    <FetchAnswerAfternoonContext.Provider value={{ state, dispatch, refetch }}>
      {children}
    </FetchAnswerAfternoonContext.Provider>
  );
}

export type DeleteAnswerAfternoon = {
  id: string;
  password: string | null;
};

const deleteAnswerReducer = (
  state: State<DeleteAnswerAfternoon>,
  action: Action<DeleteAnswerAfternoon>
): State<DeleteAnswerAfternoon> => {
  switch (action.type) {
    case "processing":
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case "success":
      return {
        status: action.type,
        data: action.response,
        error: null,
      };
    case "error":
      return {
        status: action.type,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const initialDeleteState = createInitialState<DeleteAnswerAfternoon>();

export const DeleteAnswerAfternoonContext = createContext<
  ContextType<DeleteAnswerAfternoon>
>({
  state: initialDeleteState,
});

export const deleteAnswerAfternoon = (
  context: ContextType<DeleteAnswerAfternoon>,
  params: DeleteAnswerAfternoon,
  handleError: (hasError: boolean, error?: unknown) => void
) => {
  const url = process.env.REACT_APP_SERVER_PATH + "/answerAfternoon";

  if (context.dispatch == null) {
    return context.state;
  }

  context.dispatch({ type: "processing", response: null, error: null });

  (async () => {
    try {
      const response = await axios.delete(url, {
        data: params,
      });
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: "success",
        response: response.data,
        error: null,
      });
    } catch (error) {
      handleError(true, error);
    }
  })();
  return context.state;
};

export function DeleteAnswerAfternoonProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer(deleteAnswerReducer, initialDeleteState);
  const { handleError } = useContext(ErrorHandlerContext);

  const deleteAnswer = useCallback(
    (params: DeleteAnswerAfternoon) =>
      deleteAnswerAfternoon({ state, dispatch }, params, handleError),
    []
  );

  return (
    <DeleteAnswerAfternoonContext.Provider
      value={{ state, dispatch, deleteAnswer }}
    >
      {children}
    </DeleteAnswerAfternoonContext.Provider>
  );
}
