import styles from "./Top.module.scss";
import logo from "../../../static/images/logo.png";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import logoImage from "../../../static/images/header-icon.png";
import { motion } from "framer-motion";
import HeaderLayout from "../../organism/header-layout/HeaderLayout";

const Top = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <HeaderLayout
        title="IT資格 速報掲示板"
        backgroundColor="rgb(140, 121, 104)"
        color="white"
        headerIcon={logoImage}
        isMenu={true}
      >
        <motion.div
          initial={{ opacity: 0, translateY: 20 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{ delay: 0.2, duration: 0.5, ease: "backIn" }}
          className={styles.motion}
        >
          <div className={styles.mainContainer}>
            <div className={styles.content}>
              <div className={styles.mainContent}>
                <h1
                  style={{
                    fontSize: "2rem",
                    fontWeight: "800",
                  }}
                >
                  「TU SQUARE」
                </h1>
              </div>
              <div className={styles.imageWrapper}>
                <img src={logoImage} alt="" className={styles.mainIcon} />
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.mainSection}>
                <div> IPA資格の勉強をより効率的にする</div>
                <div>試験後の解答速報よりも前に知る</div>
              </div>
              <div className={styles.subSection}>
                <div>
                  資格受験の際に受験生同士のコミュニケーションがあると、自分のレベル感を掴むことができます。
                </div>
                <div>分からない問題などがあれば共有してみましょう。</div>
                <div>
                  また、受験後は自分の解答を投稿したり、受験生の解答を確認したりしてみよう。
                </div>
                <div>
                  受験の難易度や他の受験生の解答を確認することができるでしょう。
                </div>
              </div>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                key="掲示板"
                variant="contained"
                color="success"
                sx={{
                  color: "white",
                  borderColor: "#333",
                  width: "100%",
                }}
                onClick={() => navigate("/certification")}
              >
                TU SQUAREはこちらから
              </Button>
            </div>
            <div className={styles.listContainer}>
              <div className={styles.list}>
                <h2 className={styles.title}>みんなの掲示板</h2>
                <div>リアルタイムに匿名にてチャットが利用できます</div>
                <div>受験生とのコミュニケーションを図ることができます</div>
              </div>
              <div className={styles.list}>
                <h2 className={styles.title}>みんなの解答</h2>
                <div>みんなの解答を見ることができます</div>
                <div>
                  解答速報よりも早く解答が確認でき、次なるステップに役立ちます
                </div>
              </div>
            </div>
            <div className={styles.certificationArea}>
              <div className={styles.certificationTitle}>
                本サービスで提供している資格
              </div>
              <ul className={styles.certificationList}>
                <li>応用情報技術者</li>
                <li>ITストラテジスト</li>
                <li>システムアーキテクト</li>
                <li>プロジェクトマネージャー</li>
                <li>情報セキュリティマネジメント</li>
                <li>ネットワークスペシャリスト</li>
                <li>データベーススペシャリスト</li>
                <li>エンベデットシステムスペシャリスト</li>
                <li>情報安全確保支援士</li>
                <li>ITサービスマネージャー</li>
                <li>システム監査技術者</li>
              </ul>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                key="掲示板"
                variant="contained"
                color="success"
                sx={{
                  color: "white",
                  borderColor: "#333",
                  width: "100%",
                }}
                onClick={() => navigate("/certification")}
              >
                TU SQUAREはこちらから
              </Button>
            </div>
          </div>
        </motion.div>
      </HeaderLayout>
    </div>
  );
};

export default Top;
