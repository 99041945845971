import axios from "axios";
import { atom } from "jotai";
import { State, useFetchBase, usePostBase } from "./BaseApi";

export type CommentParams = {
  afternoonAnswerId: string;
  comment: string;
};

export type Comment = {
  _id: string;
  commentId: string;
  comment: string;
  createTime: string;
};

export type FetchCommentParams = {
  afternoonAnswerId: string;
};

const url = process.env.REACT_APP_SERVER_PATH + "/comment";

export const fetchCommentAtom = atom<State<Comment> | null>(null);

export const useFetchComment = () => {
  return useFetchBase<Comment, FetchCommentParams>(url, fetchCommentAtom);
};

export const postCommentAtom = atom<State<CommentParams> | null>(null);

export const usePostComment = () => {
  return usePostBase<CommentParams>(url, postCommentAtom);
};
