import { FunctionComponent } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Chat from "../components/pages/chat-page/Chat";
import Report from "../components/pages/report-page/Report";
import ReportFormPage from "../components/pages/report-form-page/ReportForm";
import Top from "../components/pages/top-page/Top";
import Error from "../components/pages/error/Error";
import Certification from "../components/pages/certification/Certification";
import AnswerMorningFormPage from "../components/pages/answer-morning-form-page/AnswerMorningFormPage";
import AnswerMorningListPage from "../components/pages/answer-morning-list-page/AnswerMorningListPage";
import AnswerAfternoonFormPage from "../components/pages/answer-afternoon-page/AnswerAfternoonFormPage";
import AnswerAfternoonList from "../components/pages/answer-afternoon-list/AnswerAfternoonList";
import PrivacyPolicy from "../components/pages/privacy-policy/PrivacyPolicy";

interface RouterProps {}

const Router: FunctionComponent<RouterProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to={"/top"} />} />
      <Route path="/certification" element={<Certification></Certification>} />
      <Route path="/chat" element={<Chat></Chat>} />
      <Route path="/report" element={<Report></Report>} />
      <Route path="/report-form" element={<ReportFormPage></ReportFormPage>} />
      <Route path="/top" element={<Top></Top>} />
      <Route
        path="/answer-afternoon-form"
        element={<AnswerAfternoonFormPage></AnswerAfternoonFormPage>}
      ></Route>
      <Route
        path="/answer-morning-form"
        element={<AnswerMorningFormPage></AnswerMorningFormPage>}
      ></Route>
      <Route
        path="/answer-morning-list"
        element={<AnswerMorningListPage></AnswerMorningListPage>}
      ></Route>
      <Route
        path="/answer-afternoon-list"
        element={<AnswerAfternoonList></AnswerAfternoonList>}
      ></Route>
      <Route
        path="privacy-policy"
        element={<PrivacyPolicy></PrivacyPolicy>}
      ></Route>
      <Route path="/error" element={<Error></Error>}></Route>
      <Route path="*" element={<Navigate replace to={"/error"} />}></Route>
    </Routes>
  );
};

export default Router;
