import { ANSWER_MORNING1 } from "../../constant/const";
import { GetCertification } from "../hooks/reducer/CertificationReducer";

export const createTitle = (
  certification: GetCertification | null,
  seazonId?: string | null,
  examLevel?: string | null
) => {
  // 本当はオプショナルで受け取りたくない
  if (certification == null) {
    return [""];
  }

  if (seazonId == null) {
    return [certification?.certificationName];
  }
  const year = certification?.seazon.filter((seazon) => {
    // TODO：絶対に直したい
    return seazon.seazonId == seazonId;
  })[0];

  if (examLevel !== null && examLevel === ANSWER_MORNING1) {
    return ["午前I", year?.seazonName];
  }

  return [certification.certificationName, year.seazonName];
};
