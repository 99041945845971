export const certificationList = [
  {
    name: "応用情報技術者",
    pageId: "OUYOU",
  },
  {
    name: "ITストラテジスト",
    pageId: "ITSTRATEGY",
  },
  {
    name: "システムアーキテクト",
    pageId: "SYSTEMARCHITECT",
  },
  {
    name: "プロジェクトマネージャー",
    pageId: "PUROMANE",
  },
  {
    name: "ネットワークスペシャリスト",
    pageId: "NESUPE",
  },
  {
    name: "データベーススペシャリスト",
    pageId: "DBSPE",
  },
  {
    name: "情報安全確保支援士",
    pageId: "SEKISUPE",
  },
];
