import {
  Context,
  Dispatch,
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { Action, State, createInitialState } from "./state";
import {
  ChangeLoadingState,
  LoadingContext,
} from "../../molecules/loading/Loading";
import axios from "axios";
import { ReactNode } from "react";
import {
  ChangeErrorHandlerState,
  ErrorHandlerContext,
} from "../../molecules/error-handler/ErrorHandler";

export type GetChatMassageResponse = {
  _id: string;
  messageId: number;
  message: string;
  replyMessageId?: string;
  replyMessage?: string;
  createTime: string;
};

type ContextType<T> = {
  state: State<T>;
  dispatch?: Dispatch<Action<T>>;
  refetch?: (params: GetMessageParams) => State<T>;
  post?: (request: T) => State<T>;
  resetState?: () => void;
};

const reducer = (
  state: State<GetChatMassageResponse>,
  action: Action<GetChatMassageResponse>
): State<GetChatMassageResponse> => {
  switch (action.type) {
    case "processing":
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case "success":
      return {
        status: action.type,
        data: action.response,
        error: null,
      };
    case "error":
      return {
        status: action.type,
        data: null,
        error: action.error,
      };
    case "reset":
      return createInitialState();
    default:
      return state;
  }
};

const postReducer = (
  state: State<PostChatMassageRequest>,
  action: Action<PostChatMassageRequest>
): State<PostChatMassageRequest> => {
  switch (action.type) {
    case "processing":
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case "success":
      return {
        status: action.type,
        data: action.response,
        error: null,
      };
    case "error":
      return {
        status: action.type,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const initialState = createInitialState<GetChatMassageResponse>();

export const GetChatMessageContext = createContext<
  ContextType<GetChatMassageResponse>
>({
  state: initialState,
});

export const initialPostState = createInitialState<PostChatMassageRequest>();

export const PostChatMessageContext = createContext<
  ContextType<PostChatMassageRequest>
>({
  state: initialPostState,
});

export type GetMessageParams = {
  pageId: string | null;
};

export type PostChatMassageRequest = {
  pageId: string | null;
  message: string | null;
  replyId?: string;
  replyMessage?: string;
};

export const getChatMessage = (
  context: ContextType<GetChatMassageResponse>,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void,
  params: GetMessageParams
) => {
  const url = process.env.REACT_APP_SERVER_PATH + "/chatMessage";

  if (context.dispatch == null) {
    return context.state;
  }

  //   changeLoadingState(true);
  context.dispatch({ type: "processing", response: null, error: null });

  (async () => {
    try {
      const response = await axios.get(url, {
        params,
      });
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: "success",
        response: response.data,
        error: null,
      });
      //   changeLoadingState(false);
    } catch (error) {
      // changeLoadingState(false);
      handleError(true, error);
    }
  })();
  return context.state;
};

export function FetchChatMessageProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const refetch = useCallback(
    (params: GetMessageParams) =>
      getChatMessage(
        { state, dispatch },
        changeLoadingState,
        handleError,
        params
      ),
    []
  );

  const resetState = () => {
    dispatch({ type: "reset", response: null, error: null });
  };

  return (
    <GetChatMessageContext.Provider
      value={{ state, dispatch, refetch, resetState }}
    >
      {children}
    </GetChatMessageContext.Provider>
  );
}

export const postChatMessage = (
  context: ContextType<PostChatMassageRequest>,
  params: PostChatMassageRequest,
  handleError: (hasError: boolean, error?: unknown) => void
) => {
  const url = process.env.REACT_APP_SERVER_PATH + "/chatMessage";

  if (context.dispatch == null) {
    return context.state;
  }

  context.dispatch({ type: "processing", response: null, error: null });

  (async () => {
    try {
      const response = await axios.post(url, {
        pageId: params.pageId,
        message: params.message,
        replyId: params.replyId,
        replyMessage: params.replyMessage,
      });
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: "success",
        response: response.data,
        error: null,
      });
    } catch (error) {
      handleError(true, error);
    }
  })();
  return context.state;
};

export function FetchPostChatMessageProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer(postReducer, initialPostState);
  const { handleError } = useContext(ErrorHandlerContext);

  const post = useCallback(
    (params: PostChatMassageRequest) =>
      postChatMessage({ state, dispatch }, params, handleError),
    []
  );

  return (
    <PostChatMessageContext.Provider value={{ state, dispatch, post }}>
      {children}
    </PostChatMessageContext.Provider>
  );
}
