import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Menu.module.scss";
import { AnimatePresence, motion, Variants } from "framer-motion";
import HomeIcon from "@mui/icons-material/Home";
import ChatIcon from "@mui/icons-material/Chat";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useState } from "react";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useAtom } from "jotai";
import { menuAtom } from "../header/Header";
import { useCertification } from "../../hooks/custom-hooks/useCertification";
import React from "react";

const Menu = () => {
  const navigate = useNavigate();
  const { certifications } = useCertification();
  const menuCertification = certifications?.filter(
    (c) => c.certificationId !== "MORNING1"
  );

  const [isMenu, setIsMenu] = useAtom(menuAtom);

  const closeMenu = () => {
    setIsMenu(false);
  };

  const transit = (path: string) => {
    navigate(path);
    setIsMenu(false);
  };

  const variants: Variants = {
    initial: {
      x: "100%",
    },
    animate: {
      x: 0,
    },
    exit: {
      x: "100%",
    },
  };

  return (
    <>
      <AnimatePresence>
        {isMenu && (
          <div className={styles.overlay}>
            <motion.div
              className={styles.menuWrapper}
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <div className={styles.title}>Menu</div>
              <div className={styles.closeIcon}>
                <CloseSharpIcon
                  sx={{ fontSize: "1.4rem", color: "#333" }}
                  onClick={closeMenu}
                />
              </div>
              <div className={styles.container}>
                <div className={styles.list} onClick={() => transit("/")}>
                  <HomeIcon />
                  <div className={styles.menuTitle}>TU SQUARE</div>
                </div>
                <div className={styles.miniDivider}></div>
                {menuCertification?.map((c, index) => (
                  <React.Fragment key={`${c.certificationId}-${index}`}>
                    <div
                      className={styles.list}
                      onClick={() =>
                        transit(`/certification?pageId=${c.certificationId}`)
                      }
                    >
                      <ChatIcon />
                      <div className={styles.menuTitle}>
                        {c.certificationName}
                      </div>
                    </div>
                    <div className={styles.miniDivider}></div>
                  </React.Fragment>
                ))}
                <div
                  className={styles.list}
                  onClick={() => transit("/privacy-policy")}
                >
                  <PrivacyTipIcon />
                  <div className={styles.menuTitle}>プライバシーポリシー</div>
                </div>
                <div className={styles.miniDivider}></div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Menu;
