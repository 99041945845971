import { FunctionComponent, memo, useState } from "react";
import styles from "./Header.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "../menu/Menu";

type HeaderProps = {
  title?: string;
  backgroundColor?: string;
  color: string;
  textAlign?: "center" | "left";
  headerIcon?: string;
  isBackIcon?: boolean;
  isMenu?: boolean;
  seazonId?: string | null;
};

const Header: FunctionComponent<HeaderProps> = memo((props) => {
  const navigate = useNavigate();

  const [isMenu, setIsMenu] = useState(false);

  const openMenu = () => {
    setIsMenu(!isMenu);
  };

  return (
    <>
      {/* TODO：スタイルを受け渡す */}
      <div
        className={styles.header}
        style={{
          background: props.backgroundColor,
          color: props.color,
          textAlign: props.textAlign,
        }}
      >
        <div className={styles.backIcon}>
          {props.isBackIcon && (
            <ArrowBackIosIcon onClick={() => navigate("/certification")} />
          )}
        </div>
        {props.headerIcon && (
          <img
            className={styles.headerIcon}
            src={props.headerIcon}
            alt={props.headerIcon}
            onClick={() => navigate("/certification")}
          />
        )}
        <h1 className={styles.title}>{props.title}</h1>
        <div className={styles.rightIcon}>
          {props.isMenu && (
            <MenuIcon onClick={() => openMenu()} fontSize="large" />
          )}
        </div>
        {isMenu && <Menu isMenu={isMenu} seazonId={props.seazonId}></Menu>}
      </div>
    </>
  );
});

export default Header;
