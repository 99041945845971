// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacyPolicy_container__l69Ww {
  width: calc(100% - 4rem);
  margin: auto;
  padding: 1rem 0 3rem;
}
.PrivacyPolicy_container__l69Ww h1 {
  font-size: 1.4rem;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/privacy-policy/PrivacyPolicy.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,YAAA;EACA,oBAAA;AACF;AAAE;EACE,iBAAA;AAEJ","sourcesContent":[".container {\n  width: calc(100% - 4rem);\n  margin: auto;\n  padding: 1rem 0 3rem;\n  h1 {\n    font-size: 1.4rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PrivacyPolicy_container__l69Ww`
};
export default ___CSS_LOADER_EXPORT___;
