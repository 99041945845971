import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Menu.module.scss";
import { motion } from "framer-motion";
import HomeIcon from "@mui/icons-material/Home";
import ChatIcon from "@mui/icons-material/Chat";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

interface MenuProps {
  isMenu: boolean;
  seazonId?: string | null;
}

const Menu = (props: MenuProps) => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const certificationId = query?.get("pageId");
  const seazonId = query?.get("seazon");

  const displayAnswer = () => {
    return (
      (certificationId != null && props.seazonId != null) ||
      (certificationId != null && seazonId != null)
    );
  };
  return (
    <motion.div
      initial={{ opacity: 0, translateY: -20 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ delay: 0.2, duration: 0.2, ease: "easeIn" }}
      className={styles.motion}
    >
      <div className={styles.menu}>
        <div className={styles.title}>Menu</div>
        <div className={styles.container}>
          <div className={styles.list} onClick={() => navigate("/")}>
            <RemoveRedEyeIcon />
            <div className={styles.menuTitle}>TU SQUAREとは</div>
          </div>
          <div className={styles.miniDivider}></div>
          <div
            className={styles.list}
            onClick={() => navigate("/certification")}
          >
            <HomeIcon />
            <div className={styles.menuTitle}>資格一覧</div>
          </div>
          <div className={styles.miniDivider}></div>
          {certificationId != null && (
            <>
              <div
                className={styles.list}
                onClick={() => navigate("/chat?pageId=" + certificationId)}
              >
                <ChatIcon />
                <div className={styles.menuTitle}>チャットを見る</div>
              </div>
              <div className={styles.miniDivider}></div>
            </>
          )}
          {displayAnswer() && (
            <>
              <div
                className={styles.list}
                onClick={() =>
                  navigate(
                    `/answer-morning-list?pageId=${certificationId}&seazon=${
                      seazonId == null ? props.seazonId : seazonId
                    }`
                  )
                }
              >
                <ChecklistRtlIcon />
                <div className={styles.menuTitle}>午前解答を見る</div>
              </div>
              <div className={styles.miniDivider}></div>
              <div
                className={styles.list}
                onClick={() =>
                  navigate(
                    `/answer-afternoon-list?pageId=${certificationId}&seazon=${
                      seazonId == null ? props.seazonId : seazonId
                    }`
                  )
                }
              >
                <EditNoteIcon />
                <div className={styles.menuTitle}>午後解答を見る</div>
              </div>
              <div className={styles.miniDivider}></div>
            </>
          )}
          <div
            className={styles.list}
            onClick={() => navigate("/privacy-policy")}
          >
            <PrivacyTipIcon />
            <div className={styles.menuTitle}>プライバシーポリシー</div>
          </div>
          <div className={styles.miniDivider}></div>
        </div>
      </div>
    </motion.div>
  );
};

export default Menu;
