import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";

type PulldownProps = {
  id: string;
  name: string;
  title?: string;
  items: SelectItem[];
  value?: string;
  selectedValue?: string;
  handlePulldownChange: (event: SelectFormResult) => void;
};

export type SelectItem = {
  name: string;
  value: string;
};

export type SelectFormResult = {
  name: string;
  value: string;
};

const Pulldown = (props: PulldownProps) => {
  const [selectedValue, setValue] = React.useState(props.selectedValue ?? "");

  const handleChange = (value: string) => {
    props.handlePulldownChange({ value, name: props.name });
    setValue(value);
  };

  return (
    <>
      <FormControl variant="outlined" sx={{ minWidth: 115 }}>
        <InputLabel id="demo-simple-select-label">{props.title}</InputLabel>
        <Select
          labelId={props.id}
          id={props.id}
          value={selectedValue}
          label="選択"
          sx={{
            bgcolor: "background.paper",
          }}
          onChange={(e) => handleChange(e.target.value)}
        >
          {props.items.map((item, index) => (
            <MenuItem value={item.value} key={`${props.id}-${index}`}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default Pulldown;
