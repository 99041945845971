import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export type ChoiceButtonProps = {
  id: string;
  name: string;
  selectItems: SelectItem[];
  handleChoiceButtonChange: (event: ToggleResult) => void;
};

export type SelectItem = {
  name: string;
  value: string;
};

export type ToggleResult = {
  value: string;
  name: string;
};

const ChoiceButton = (props: ChoiceButtonProps) => {
  const [item, setItem] = React.useState("");

  const choiceButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    newItem: string
  ) => {
    setItem(newItem);
    props.handleChoiceButtonChange({ value: newItem, name: props.name });
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={item}
      exclusive
      onChange={choiceButtonChange}
      aria-label="Platform"
      fullWidth={true}
      sx={{
        bgcolor: "background.paper",
      }}
    >
      {props.selectItems.map((item, index) => (
        <ToggleButton
          value={item.value}
          key={`${props.id}-${index}`}
          sx={{ fontSize: "1rem", fontWeight: "bold" }}
        >
          {item.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
export default ChoiceButton;
