export type MetaData = {
  title: string;
  description: string;
};

export const metaForHelmet = {
  OUYOU: {
    title: "応用情報技術者　速報掲示板",
    description:
      "応用情報技術者試験の速報掲示板です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  ITSTRATEGY: {
    title: "ITストラテジスト　速報掲示板",
    description:
      "ITストラテジストの速報掲示板です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  SYSTEMARCHITECT: {
    title: "システムアーキテクト　速報掲示板",
    description:
      "システムアーキテクトの速報掲示板です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  PUROMANE: {
    title: "プロジェクトマネージャー　速報掲示板",
    description:
      "プロジェクトマネージャーの速報掲示板です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  SEKYUMANE: {
    title: "情報セキュリティマネジメント　速報掲示板",
    description:
      "情報セキュリティマネジメントの速報掲示板です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  NESUPE: {
    title: "ネットワークスペシャリスト　速報掲示板",
    description:
      "ネットワークスペシャリストの速報掲示板です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  DBSPE: {
    title: "データベーススペシャリスト　速報掲示板",
    description:
      "データベーススペシャリストの速報掲示板です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  ENVEDETTO: {
    title: "エンベデットシステムスペシャリスト　速報掲示板",
    description:
      "エンベデットシステムスペシャリストの速報掲示板です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  SEKISUPE: {
    title: "情報安全確保支援士　速報掲示板",
    description:
      "情報安全確保支援士の速報掲示板です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  ITSERVICE: {
    title: "ITサービスマネージャー　速報掲示板",
    description:
      "ITサービスマネージャーの速報掲示板です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  SYSKAN: {
    title: "システム監査技術者　速報掲示板",
    description:
      "システム監査技術者の速報掲示板です。受験者による解答速報やスレッドでの議論が可能です。",
  },
};

export const metaAnswerForHelmet = {
  OUYOU: {
    title: "応用情報技術者　午前解答速報",
    description:
      "応用情報技術者試験の解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  ITSTRATEGY: {
    title: "ITストラテジスト　午前解答速報",
    description:
      "ITストラテジストの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  SYSTEMARCHITECT: {
    title: "システムアーキテクト　午前解答速報",
    description:
      "システムアーキテクトの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  PUROMANE: {
    title: "プロジェクトマネージャー　午前解答速報",
    description:
      "プロジェクトマネージャーの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  SEKYUMANE: {
    title: "情報セキュリティマネジメント　午前解答速報",
    description:
      "情報セキュリティマネジメントの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  NESUPE: {
    title: "ネットワークスペシャリスト　午前解答速報",
    description:
      "ネットワークスペシャリストの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  DBSPE: {
    title: "データベーススペシャリスト　午前解答速報",
    description:
      "データベーススペシャリストの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  ENVEDETTO: {
    title: "エンベデットシステムスペシャリスト　午前解答速報",
    description:
      "エンベデットシステムスペシャリストの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  SEKISUPE: {
    title: "情報安全確保支援士　午前解答速報",
    description:
      "情報安全確保支援士の解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  ITSERVICE: {
    title: "ITサービスマネージャー　午前解答速報",
    description:
      "ITサービスマネージャーの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  SYSKAN: {
    title: "システム監査技術者　午前解答速報",
    description:
      "システム監査技術者の解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
};

export const metaAnswerAfternoonForHelmet = {
  OUYOU: {
    title: "応用情報技術者　午後解答速報",
    description:
      "応用情報技術者試験の解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  ITSTRATEGY: {
    title: "ITストラテジスト　午後解答速報",
    description:
      "ITストラテジストの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  SYSTEMARCHITECT: {
    title: "システムアーキテクト　午後解答速報",
    description:
      "システムアーキテクトの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  PUROMANE: {
    title: "プロジェクトマネージャー　午後解答速報",
    description:
      "プロジェクトマネージャーの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  SEKYUMANE: {
    title: "情報セキュリティマネジメント　午後解答速報",
    description:
      "情報セキュリティマネジメントの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  NESUPE: {
    title: "ネットワークスペシャリスト　午後解答速報",
    description:
      "ネットワークスペシャリストの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  DBSPE: {
    title: "データベーススペシャリスト　午後解答速報",
    description:
      "データベーススペシャリストの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  ENVEDETTO: {
    title: "エンベデットシステムスペシャリスト　午後解答速報",
    description:
      "エンベデットシステムスペシャリストの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  SEKISUPE: {
    title: "情報安全確保支援士　午後解答速報",
    description:
      "情報安全確保支援士の解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  ITSERVICE: {
    title: "ITサービスマネージャー　午後解答速報",
    description:
      "ITサービスマネージャーの解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
  SYSKAN: {
    title: "システム監査技術者　午後解答速報",
    description:
      "システム監査技術者の解答速報です。受験者による解答速報やスレッドでの議論が可能です。",
  },
};
