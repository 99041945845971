import { FunctionComponent } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import styles from "./CertificationAccordion.module.scss";

// だいぶ下手くそだと思うこれ
export type AccordionDetail = {
  seazonId: string;
  content: string;
  query1: string;
  query2: string;
};

export type AccordionType = {
  key: string | number;
  title: string;
  detail?: AccordionDetail[];
};

type AccordionProps = {
  object: AccordionType;
};

const CertificationAccordion = (props: AccordionProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{props.object.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.button}>
            <Button
              variant="outlined"
              sx={{
                color: "#333",
                borderColor: "#333",
                width: "100%",
              }}
              onClick={() => navigate(`/chat?pageId=${props.object.key}`)}
            >
              掲示板
            </Button>
          </div>
          {props.object.detail?.map((detail, index) => (
            <div className={styles.answerArea} key={index}>
              <div className={styles.answerTitle}>{detail.content}</div>
              <Button
                variant="outlined"
                sx={{
                  color: "#333",
                  borderColor: "#333",
                  width: "100%",
                }}
                onClick={() =>
                  navigate(
                    `/answer-morning-list?pageId=${props.object.key}&seazon=${detail.seazonId}`
                  )
                }
              >
                午前解答
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#333",
                  borderColor: "#333",
                  width: "100%",
                }}
                onClick={() =>
                  navigate(
                    `/answer-afternoon-list?pageId=${props.object.key}&seazon=${detail.seazonId}`
                  )
                }
              >
                午後解答
              </Button>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CertificationAccordion;
