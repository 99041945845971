import { useContext, useEffect, useRef, useState } from "react";
import styles from "./ReportForm.module.scss";
import {
  GetReportContext,
  PostReportContext,
  GetReportParams,
} from "../../hooks/reducer/ReportReducer";
import { useLocation } from "react-router-dom";
import { Report } from "../../pages/report-page/Report";
import HeaderLayout from "../../organism/header-layout/HeaderLayout";
import { createTitle } from "../../utils/header-util";
import ReportForm from "../../organism/report-form/ReportForm";
import { useCertification } from "../../hooks/custom-hooks/useCertification";
type ReportFooterProps = {
  background?: string;
  report?: Report | null;
  scroll?: (behavior: "smooth") => void;
};

const ReportFormPage = (props: ReportFooterProps) => {
  const getReportContext = useContext(GetReportContext);
  const postReportContext = useContext(PostReportContext);

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const certificationId = query?.get("pageId");
  const { certifications } = useCertification();
  const targetCertification = certifications?.filter(
    (c) => c.certificationId === certificationId
  )[0];

  useEffect(() => {
    const data = postReportContext?.state?.data;

    if (data == null) {
      return;
    }

    const getParams: GetReportParams = {
      pageId: certificationId,
    };

    getReportContext?.refetch?.(getParams);
  }, [postReportContext]);

  const createPath = () => {
    return `/report?pageId=${certificationId}`;
  };

  return (
    <div>
      <HeaderLayout
        title={createTitle(targetCertification ?? null)}
        backgroundColor="#FFFFFF"
        color="#333"
        textAlign="center"
        isBackIcon={true}
        isMenu={true}
        // seazonId={seazonId}
        path={createPath()}
      >
        <ReportForm />
      </HeaderLayout>
    </div>
  );
};

export default ReportFormPage;
