import HeaderLayout from "../../organism/header-layout/HeaderLayout";
import styles from "./AnswerAfternoonFormPage.module.scss";
import { useLocation } from "react-router-dom";
import { createTitle } from "../../utils/header-util";
import AnswerAfternoonForm from "../../organism/answer-afternoon-form/AnswerAfternoonForm";
import { useCertification } from "../../hooks/custom-hooks/useCertification";
const AnswerAfternoonFormPage = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const certificationId = query?.get("pageId"); //ヘッダ表示用にいる
  const seazonId = query?.get("seazon"); //ヘッダ表示用にいる
  const { certifications } = useCertification();
  const targetCertification = certifications?.filter(
    (c) => c.certificationId === certificationId
  )[0];

  return (
    <div className={styles.answerContainer}>
      <HeaderLayout
        title={createTitle(targetCertification ?? null, seazonId)}
        backgroundColor="#FFFFFF"
        color="#333"
        isBackIcon={true}
        textAlign="center"
        isMenu={true}
        path={`/answer-afternoon-list?pageId=${certificationId}&seazon=${seazonId}`}
      >
        <div className={styles.formContainer}>
          <AnswerAfternoonForm />
        </div>
      </HeaderLayout>
    </div>
  );
};

export default AnswerAfternoonFormPage;
