import styles from "./Top.module.scss";
import logo from "../../../static/images/logo.png";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import logoImage from "../../../static/images/header-icon.png";
import mainImage from "../../../static/images/main-icon.png";
import { motion } from "framer-motion";
import HeaderLayout from "../../organism/header-layout/HeaderLayout";
import { certificationList } from "./toppage-const";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Top = () => {
  const navigate = useNavigate();

  const navigateCertification = (params: string) => {
    navigate(`/certification?pageId=${params}`);
  };

  return (
    <div className={styles.container}>
      <HeaderLayout
        title={["IT資格 速報掲示板"]}
        backgroundColor="#FFFFFF"
        color="#333"
        headerIcon={logoImage}
        isMenu={true}
      >
        <div className={styles.mainContainer}>
          <div className={styles.content}>
            <div className={styles.imageWrapper}>
              <img src={mainImage} alt="" className={styles.mainIcon} />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.mainSection}>
              <h2 className={styles.title}>IT資格の速報掲示板</h2>
            </div>
            <div className={styles.subSection}>
              <div>IPA資格の勉強をより効率的に行いたい</div>
              <div>試験後にすぐに他の受験生の解答を確認したい</div>
              <div>そんなあなたにピッタリのサービスです</div>
            </div>
          </div>
          <div className={styles.listContainer}>
            <div className={styles.list}>
              <h2 className={styles.title}>みんなの掲示板</h2>
              <div>試験前に経験者の声を聞いてみませんか？</div>
              <div>試験問題について受験者同士で話してみませんか？</div>
            </div>
            <div className={styles.list}>
              <h2 className={styles.title}>みんなの解答</h2>
              <div>試験後にみんなの解答を見てみませんか？</div>
              <div>受験者の解答を基に議論してみませんか？</div>
            </div>
          </div>
          <div className={styles.certificationArea}>
            <h2 className={styles.certificationTitle}>資格一覧</h2>
            {certificationList.map((certification) => (
              <div
                key={certification.name}
                className={styles.certification}
                onClick={() => navigateCertification(certification.pageId)}
              >
                <div>
                  <div className={styles.certificationName}>
                    {certification.name}
                  </div>
                  <div className={styles.subtitle}>Click to View Details</div>
                </div>
                <div className={styles.arrowIcon}>
                  <ArrowForwardIosIcon></ArrowForwardIosIcon>
                </div>
              </div>
            ))}
          </div>
        </div>
      </HeaderLayout>
    </div>
  );
};

export default Top;
