import { ReactNode, createContext, memo, useContext, useState } from "react";
import styles from "./Loading.module.scss";
import HashLoader from "react-spinners/HashLoader";

export type ChangeLoadingState = (flag: boolean) => void;

export const LoadingContext = createContext<{
  loadingState: boolean;
  changeLoadingState: ChangeLoadingState;
}>({
  loadingState: false,
  changeLoadingState: (flag: boolean) => {},
});

export const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [loadingState, setLoadingState] = useState(false);

  const changeLoadingState = (flag: boolean) => {
    if (flag) {
      setLoadingState(flag);
      return;
    }

    setTimeout(() => setLoadingState(flag), 1000);
  };

  return (
    <LoadingContext.Provider value={{ loadingState, changeLoadingState }}>
      {children}
    </LoadingContext.Provider>
  );
};

const Loading = memo(() => {
  const { loadingState } = useContext(LoadingContext);

  return (
    <>
      {loadingState && (
        <div className={styles.container}>
          <HashLoader color={"#FFBB7A"} size={80} className={styles.loader} />
        </div>
      )}
    </>
  );
});

export default Loading;
