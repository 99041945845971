import { FunctionComponent, useContext, useEffect, useState } from "react";
import styles from "./Certification.module.scss";
import { LoadingContext } from "../../molecules/loading/Loading";
import { GetCertificationContext } from "../../hooks/reducer/CertificationReducer";
import logoImage from "../../../static/images/header-icon.png";
import { Helmet } from "react-helmet";
import HeaderLayout from "../../organism/header-layout/HeaderLayout";
import CertificationAccordion, {
  AccordionDetail,
  AccordionType,
} from "../../organism/certification-accordion/CertificationAccordion";

export type Seazon = {
  seazonId: string;
  seazonName: string;
};

export type CertificationType = {
  certificationId: string;
  certificationName: string;
  seazon: Seazon[];
};

const Certification = () => {
  const [certifications, setCertifications] = useState<AccordionType[]>();
  const { loadingState, changeLoadingState } = useContext(LoadingContext);
  const getCertificationContext = useContext(GetCertificationContext);

  // ここで取得API呼び出す
  useEffect(() => {
    changeLoadingState(true);
    getCertificationContext?.refetch?.();
  }, []);

  useEffect(() => {
    const response = getCertificationContext?.state?.data;

    if (response == null) {
      return;
    }

    const certification: AccordionType[] = response.map(
      (e: CertificationType) => {
        const accordionDetail: AccordionDetail[] = e.seazon.map((s) => {
          return {
            seazonId: s.seazonId,
            content: s.seazonName,
            query1: "/chat?pageId=",
            query2: "&seazon=",
          };
        });

        return {
          key: e.certificationId,
          title: e.certificationName,
          detail: accordionDetail,
        };
      }
    );
    setCertifications(certification);
    changeLoadingState(false);
  }, [getCertificationContext?.state?.data]);

  return (
    <div className={styles["home-wrapper"]}>
      <Helmet
        title={"IT資格 速報掲示板"}
        meta={[
          {
            name: "description",
            content:
              "IT資格の掲示板です。IT資格ごとに掲示板や解答速報ページを用意しております。",
          },
        ]}
      />
      <HeaderLayout
        title="IT資格 速報掲示板"
        backgroundColor="rgb(140, 121, 104)"
        color="white"
        headerIcon={logoImage}
        isMenu={true}
      >
        <div className={styles["title-wrapper"]}>
          <div className={styles["message"]}>資格を選択してください</div>
        </div>
        <div className={styles["accordion-wrapper"]}>
          {certifications?.map((certificate, index) => (
            <div key={index} className={styles.accordion}>
              <CertificationAccordion
                object={certificate}
              ></CertificationAccordion>
            </div>
          ))}
        </div>
      </HeaderLayout>
    </div>
  );
};

export default Certification;
