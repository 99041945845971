import { useEffect, useState } from "react";
import { metaAnswerAfternoonForHelmet, MetaData } from "../../../constant/meta";
import { useLocation } from "react-router-dom";

export const useHelmetHeader = () => {
  const [metaProp, setMetaProp] = useState<MetaData>();
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const certificationId = query?.get("pageId");
  useEffect(() => {
    if (certificationId == null) {
      return;
    }

    switch (certificationId) {
      case "OUYOU":
        setMetaProp(metaAnswerAfternoonForHelmet.OUYOU);
        break;
      case "ITSTRATEGY":
        setMetaProp(metaAnswerAfternoonForHelmet.ITSTRATEGY);
        break;
      case "SYSTEMARCHITECT":
        setMetaProp(metaAnswerAfternoonForHelmet.SYSTEMARCHITECT);
        break;
      case "PUROMANE":
        setMetaProp(metaAnswerAfternoonForHelmet.PUROMANE);
        break;
      case "SEKYUMANE":
        setMetaProp(metaAnswerAfternoonForHelmet.SEKYUMANE);
        break;
      case "NESUPE":
        setMetaProp(metaAnswerAfternoonForHelmet.NESUPE);
        break;
      case "DBSPE":
        setMetaProp(metaAnswerAfternoonForHelmet.DBSPE);
        break;
      case "ENVEDETTO":
        setMetaProp(metaAnswerAfternoonForHelmet.ENVEDETTO);
        break;
      case "SEKISUPE":
        setMetaProp(metaAnswerAfternoonForHelmet.SEKISUPE);
        break;
      case "ITSERVICE":
        setMetaProp(metaAnswerAfternoonForHelmet.ITSERVICE);
        break;
      case "SYSKAN":
        setMetaProp(metaAnswerAfternoonForHelmet.SYSKAN);
        break;
      default:
    }
  }, [certificationId]);

  return { metaProp };
};
