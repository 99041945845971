import { useContext, useEffect, useState } from "react";
import { AccordionType } from "../../organism/certification-accordion/CertificationAccordion";
import {
  GetCertification,
  GetCertificationContext,
} from "../reducer/CertificationReducer";

export const useCertification = () => {
  const [certifications, setCertifications] = useState<GetCertification[]>();
  const getCertificationContext = useContext(GetCertificationContext);
  useEffect(() => {
    if (getCertificationContext?.state?.data == null) {
      getCertificationContext?.refetch?.();
    }
  }, []);

  useEffect(() => {
    const response = getCertificationContext?.state?.data;
    if (response == null) {
      return;
    }
    setCertifications(response);
  }, [getCertificationContext?.state?.data]);

  return { certifications };
};
