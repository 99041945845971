import { Button } from "@mui/material";
import { CSSProperties, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ANSWER_MORNING_EIGHTY } from "../../../constant/const";
import { GetAnswerMorningResponse } from "../../hooks/reducer/AnswerMorningReducer";
import styles from "./AnswerMorningList.module.scss";

interface AnswerAggregateMorningProps {
  answers: GetAnswerMorningResponse[];
}

type DisplayAnswer = GetAnswerMorningResponse & {
  maxAnswer: number;
  reliability: number;
};

const AnswerMorningList = (props: AnswerAggregateMorningProps) => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const certificationId = query?.get("pageId");
  const seazonId = query?.get("seazon");
  const [displayAnswers, setDisplayAnswers] = useState<DisplayAnswer[]>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setDisplayAnswers(
      props.answers.map((answer) => {
        const sum =
          answer.answerA + answer.answerB + answer.answerC + answer.answerD;
        const maxAnswer = Math.max(
          answer.answerA,
          answer.answerB,
          answer.answerC,
          answer.answerD
        );
        return {
          no: answer.no,
          answerA: answer.answerA,
          answerB: answer.answerB,
          answerC: answer.answerC,
          answerD: answer.answerD,
          maxAnswer,
          reliability: sum === 0 ? 0 : Math.round((maxAnswer / sum) * 100),
        };
      })
    );
  }, [props]);

  const createCellStyle = (
    answers: DisplayAnswer,
    targetAnswerNumber: number
  ): CSSProperties => {
    if (targetAnswerNumber === 0) {
      return {};
    }

    if (targetAnswerNumber === answers.maxAnswer) {
      return {
        backgroundColor: "#e6a11a",
        color: "white",
        fontWeight: "800",
      };
    }
    return {};
  };

  const answer = () => {
    navigate(
      `/answer-morning-form?pageId=${certificationId}&seazon=${seazonId}`
    );
  };
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div>
          みんなの解答結果
          {certificationId !== ANSWER_MORNING_EIGHTY ? (
            <span>(午前Ⅱ)</span>
          ) : (
            <span>(午前)</span>
          )}
        </div>
      </div>
      <div className={styles.titleContainer}>
        <Button
          variant="contained"
          className={styles.submitButton}
          color="success"
          onClick={() => answer()}
        >
          回答する
        </Button>
      </div>
      <div className={styles.answerArea}>
        <div className={styles.tableContainer}>
          <table className={styles.table} border={1}>
            <thead>
              <tr>
                <th>問題</th>
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D</th>
              </tr>
            </thead>
            <tbody>
              {displayAnswers &&
                displayAnswers.map((answer, index) => (
                  <tr key={index}>
                    <td className={styles.probremNo}>
                      問{answer.no}{" "}
                      <span className={styles.reliability}>
                        {answer.reliability}%
                      </span>
                    </td>
                    <td style={createCellStyle(answer, answer.answerA)}>
                      {answer.answerA}
                    </td>
                    <td style={createCellStyle(answer, answer.answerB)}>
                      {answer.answerB}
                    </td>
                    <td style={createCellStyle(answer, answer.answerC)}>
                      {answer.answerC}
                    </td>
                    <td style={createCellStyle(answer, answer.answerD)}>
                      {answer.answerD}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AnswerMorningList;
