import { FunctionComponent, memo, useState } from "react";
import styles from "./Header.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "../menu/Menu";
import { atom, useAtom } from "jotai";
import { AnimatePresence } from "framer-motion";

type HeaderProps = {
  title?: string[];
  backgroundColor?: string;
  color: string;
  textAlign?: "center" | "left";
  headerIcon?: string;
  isBackIcon?: boolean;
  BackIconComponent?: React.ReactNode;
  isMenu?: boolean;
  seazonId?: string | null;
  path?: string;
};

export const menuAtom = atom(false);

const Header: FunctionComponent<HeaderProps> = memo((props) => {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const pageId = query.get("pageId");

  const [isMenu, setIsMenu] = useAtom(menuAtom);

  const openMenu = () => {
    setIsMenu(true);
  };

  return (
    <>
      {/* TODO：スタイルを受け渡す */}
      <div
        className={styles.header}
        style={{
          background: props.backgroundColor,
          color: props.color,
          textAlign: props.textAlign,
        }}
      >
        <div
          className={styles.backIcon}
          onClick={() =>
            navigate(props.path ?? `/certification?pageId=${pageId}`)
          }
        >
          {props.isBackIcon && <ArrowBackIosIcon />}
          {props.BackIconComponent}
        </div>
        {props.headerIcon && (
          <img
            className={styles.headerIcon}
            src={props.headerIcon}
            alt={props.headerIcon}
            onClick={() => navigate("/")}
          />
        )}
        <h1 className={styles.title}>
          {props.title?.map((t, i) => (
            <div key={i}>{t}</div>
          ))}
        </h1>
        <div className={styles.rightIcon}>
          {props.isMenu && (
            <MenuIcon
              onClick={() => openMenu()}
              fontSize="inherit"
              style={
                isMenu
                  ? { color: "#333", transition: "color 0.8s ease" }
                  : { color: "#333" }
              }
            />
          )}
        </div>
      </div>
      <Menu></Menu>
    </>
  );
});

export default Header;
