import styles from "./AnswerMorningListPage.module.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetCertificationContext } from "../../hooks/reducer/CertificationReducer";
import { createTitle } from "../../utils/header-util";
import {
  GetAnswerMorningContext,
  GetAnswerMorningResponse,
} from "../../hooks/reducer/AnswerMorningReducer";
import logoImage from "../../../static/images/header-icon.png";
import { MetaData, metaAnswerForHelmet } from "../../../constant/meta";
import { Helmet } from "react-helmet";
import HeaderLayout from "../../organism/header-layout/HeaderLayout";
import { CertificationType } from "../certification/Certification";
import AnswerMorningList from "../../organism/answer-morning-list/AnswerMorningList";

const AnswerMorningListPage = () => {
  // ここもtemplate化できる
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [metaProp, setMetaProp] = useState<MetaData>();
  const certificationId = query?.get("pageId");
  const seazonId = query?.get("seazon");
  const [certification, setCertification] = useState<CertificationType | null>(
    null
  );
  const [answers, setAnswers] = useState<GetAnswerMorningResponse[]>([]);
  const getCertificationContext = useContext(GetCertificationContext);
  const getAnswerMorningContext = useContext(GetAnswerMorningContext);
  useEffect(() => {
    const params = {
      pageId: certificationId,
      seazon: seazonId,
    };

    getCertificationContext?.refetch?.(params);
  }, []);

  useEffect(() => {
    if (certificationId == null) {
      return;
    }

    switch (certificationId) {
      case "OUYOU":
        setMetaProp(metaAnswerForHelmet.OUYOU);
        break;
      case "ITSTRATEGY":
        setMetaProp(metaAnswerForHelmet.ITSTRATEGY);
        break;
      case "SYSTEMARCHITECT":
        setMetaProp(metaAnswerForHelmet.SYSTEMARCHITECT);
        break;
      case "PUROMANE":
        setMetaProp(metaAnswerForHelmet.PUROMANE);
        break;
      case "SEKYUMANE":
        setMetaProp(metaAnswerForHelmet.SEKYUMANE);
        break;
      case "NESUPE":
        setMetaProp(metaAnswerForHelmet.NESUPE);
        break;
      case "DBSPE":
        setMetaProp(metaAnswerForHelmet.DBSPE);
        break;
      case "ENVEDETTO":
        setMetaProp(metaAnswerForHelmet.ENVEDETTO);
        break;
      case "SEKISUPE":
        setMetaProp(metaAnswerForHelmet.SEKISUPE);
        break;
      case "ITSERVICE":
        setMetaProp(metaAnswerForHelmet.ITSERVICE);
        break;
      case "SYSKAN":
        setMetaProp(metaAnswerForHelmet.SYSKAN);
        break;
      default:
    }
  }, [certificationId]);

  useEffect(() => {
    if (getCertificationContext?.state?.data?.[0] == null) {
      return;
    }
    setCertification(getCertificationContext.state.data[0]);
    const params = {
      pageId: certificationId,
      seazon: seazonId,
    };
    getAnswerMorningContext?.refetch?.(params);
  }, [getCertificationContext.state.data]);

  useEffect(() => {
    const answers = getAnswerMorningContext.state.data;

    if (answers == null) {
      return;
    }

    setAnswers(answers);
  }, [getAnswerMorningContext.state.data]);

  return (
    <div className={styles.container}>
      {metaProp && (
        <Helmet
          title={metaProp.title}
          meta={[{ name: "description", content: metaProp.description }]}
        />
      )}
      <HeaderLayout
        title={createTitle(certification, seazonId)}
        backgroundColor="#8C7968"
        color="white"
        headerIcon={logoImage}
        isMenu={true}
      >
        <div className={styles.answer}>
          <AnswerMorningList answers={answers} />
        </div>
      </HeaderLayout>
    </div>
  );
};

export default AnswerMorningListPage;
