// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Hachi+Maru+Pop&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__mEwao {
  height: 4rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.Header_title__uYh51 {
  font-size: 1.2rem;
  position: absolute;
  left: 1rem;
  top: 1.2rem;
  left: 0;
  right: 0;
  margin: 0 4rem;
  line-height: 1.25;
}

.Header_headerIcon__-d06K {
  width: 64px;
  height: 64px;
}

.Header_backIcon__uybhC {
  position: absolute;
  left: 1rem;
  top: 1.2rem;
  z-index: 9999;
}

.Header_rightIcon__V3lkb {
  position: absolute;
  right: 1rem;
  top: 0.8rem;
  font-size: 1.8rem;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/header/Header.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,gBAAA;EACA,MAAA;EACA,YAAA;AAAF;;AAGA;EACE,iBAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,OAAA;EACA,QAAA;EACA,cAAA;EACA,iBAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;AAAF;;AAGA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,aAAA;AAAF;;AAGA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,iBAAA;AAAF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Hachi+Maru+Pop&display=swap\");\n\n.header {\n  height: 4rem;\n  position: sticky;\n  top: 0;\n  z-index: 999;\n}\n\n.title {\n  font-size: 1.2rem;\n  position: absolute;\n  left: 1rem;\n  top: 1.2rem;\n  left: 0;\n  right: 0;\n  margin: 0 4rem;\n  line-height: 1.25;\n}\n\n.headerIcon {\n  width: 64px;\n  height: 64px;\n}\n\n.backIcon {\n  position: absolute;\n  left: 1rem;\n  top: 1.2rem;\n  z-index: 9999;\n}\n\n.rightIcon {\n  position: absolute;\n  right: 1rem;\n  top: 0.8rem;\n  font-size: 1.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__mEwao`,
	"title": `Header_title__uYh51`,
	"headerIcon": `Header_headerIcon__-d06K`,
	"backIcon": `Header_backIcon__uybhC`,
	"rightIcon": `Header_rightIcon__V3lkb`
};
export default ___CSS_LOADER_EXPORT___;
