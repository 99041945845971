import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoadingContext } from "../../molecules/loading/Loading";
import {
  GetChatMassageResponse,
  GetChatMessageContext,
  GetMessageParams,
} from "../../hooks/reducer/ChatReducer";
import styles from "./Chat.module.scss";
import { createTitle } from "../../utils/header-util";
import { GetCertificationContext } from "../../hooks/reducer/CertificationReducer";
import { Helmet } from "react-helmet";
import { MetaData, metaForHelmet } from "../../../constant/meta";
import ChatLayout from "../../organism/chat-layout/ChatLayout";
import HeaderLayout from "../../organism/header-layout/HeaderLayout";
import { CertificationType } from "../certification/Certification";

export type Message = {
  _id: string;
  messageId: number;
  message: string;
  replyId?: string;
  replyMessage?: string;
  createTime: string;
};

interface ChatProps {}

const Chat = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [messages, setMessages] = useState<GetChatMassageResponse[]>([]);
  const [certification, setCertification] = useState<CertificationType | null>(
    null
  );
  const [metaProp, setMetaProp] = useState<MetaData>();
  const [seazonId, setSeazonId] = useState<string | null>(null);
  const getChatMessageContext = useContext(GetChatMessageContext);
  const { loadingState, changeLoadingState } = useContext(LoadingContext);
  const getCertificationContext = useContext(GetCertificationContext);

  // collectionへの登録APIに用いる予定
  const certificationId = query?.get("pageId");

  const sortMessages = (messages: GetChatMassageResponse[]) => {
    if (!messages) {
      return [];
    }
    return messages.sort((a, b) => {
      const beforeDate = new Date(a.createTime);
      const afterDate = new Date(b.createTime);

      return beforeDate.getTime() - afterDate.getTime();
    });
  };

  useEffect(() => {
    const params = {
      pageId: certificationId,
    };

    getCertificationContext?.refetch?.(params);
  }, []);

  useEffect(() => {
    if (certificationId == null) {
      return;
    }

    switch (certificationId) {
      case "OUYOU":
        setMetaProp(metaForHelmet.OUYOU);
        break;
      case "ITSTRATEGY":
        setMetaProp(metaForHelmet.ITSTRATEGY);
        break;
      case "SYSTEMARCHITECT":
        setMetaProp(metaForHelmet.SYSTEMARCHITECT);
        break;
      case "PUROMANE":
        setMetaProp(metaForHelmet.PUROMANE);
        break;
      case "SEKYUMANE":
        setMetaProp(metaForHelmet.SEKYUMANE);
        break;
      case "NESUPE":
        setMetaProp(metaForHelmet.NESUPE);
        break;
      case "DBSPE":
        setMetaProp(metaForHelmet.DBSPE);
        break;
      case "ENVEDETTO":
        setMetaProp(metaForHelmet.ENVEDETTO);
        break;
      case "SEKISUPE":
        setMetaProp(metaForHelmet.SEKISUPE);
        break;
      case "ITSERVICE":
        setMetaProp(metaForHelmet.ITSERVICE);
        break;
      case "SYSKAN":
        setMetaProp(metaForHelmet.SYSKAN);
        break;
      default:
    }
  }, [certificationId]);

  useEffect(() => {
    if (getCertificationContext?.state?.data?.[0] == null) {
      return;
    }
    setCertification(getCertificationContext.state.data[0]);
  }, [[getCertificationContext.state.data]]);

  useEffect(() => {
    changeLoadingState(true);

    const params: GetMessageParams = {
      pageId: certificationId,
    };

    const latestSeazonId = certification?.seazon.slice(-1)[0].seazonId;

    if (latestSeazonId != null) {
      setSeazonId(latestSeazonId);
    }

    getChatMessageContext?.refetch?.(params);
  }, [certification]);

  useEffect(() => {
    const data = getChatMessageContext?.state?.data;

    if (data == null) {
      return;
    }
    setMessages(sortMessages(data));

    changeLoadingState(false);
  }, [getChatMessageContext?.state?.data]);

  return (
    <div className={styles.chatContainer}>
      {metaProp && (
        <Helmet
          title={metaProp.title}
          meta={[{ name: "description", content: metaProp.description }]}
        />
      )}
      <HeaderLayout
        title={createTitle(certification)}
        backgroundColor="#8C7968"
        color="white"
        textAlign="center"
        isBackIcon={true}
        isMenu={true}
        seazonId={seazonId}
      >
        <ChatLayout messages={messages}></ChatLayout>
      </HeaderLayout>
    </div>
  );
};

export default Chat;
