// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AnswerMorningFormPage_answerContainer__aRz9I {
  background: rgb(243, 227, 213);
}`, "",{"version":3,"sources":["webpack://./src/components/pages/answer-morning-form-page/AnswerMorningFormPage.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;AACF","sourcesContent":[".answerContainer {\n  background: rgb(243, 227, 213);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"answerContainer": `AnswerMorningFormPage_answerContainer__aRz9I`
};
export default ___CSS_LOADER_EXPORT___;
