import { FunctionComponent, ReactNode } from "react";
import Header from "../../molecules/header/Header";

type HeaderLayoutProps = {
  children: ReactNode;
  title?: string[];
  backgroundColor?: string;
  color: string;
  textAlign?: "center" | "left";
  headerIcon?: string;
  isBackIcon?: boolean;
  BackIconComponent?: ReactNode;
  isMenu?: boolean;
  seazonId?: string | null;
  path?: string;
};

const HeaderLayout: FunctionComponent<HeaderLayoutProps> = (props) => {
  const { children } = props;
  return (
    <>
      <Header
        title={props.title}
        backgroundColor={props.backgroundColor}
        color={props.color}
        textAlign={props.textAlign}
        headerIcon={props.headerIcon}
        isBackIcon={props.isBackIcon}
        isMenu={props.isMenu}
        seazonId={props.seazonId}
        BackIconComponent={props.BackIconComponent}
        path={props.path}
      ></Header>
      {children}
    </>
  );
};

export default HeaderLayout;
