import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  GetReportResponse,
  GetReportContext,
  GetReportParams,
} from "../../hooks/reducer/ReportReducer";
import styles from "./Report.module.scss";
import { createTitle } from "../../utils/header-util";
import { Helmet } from "react-helmet";
import { MetaData, metaForHelmet } from "../../../constant/meta";
import ReportLayout from "../../organism/report-layout/ReportLayout";
import HeaderLayout from "../../organism/header-layout/HeaderLayout";
import { useCertification } from "../../hooks/custom-hooks/useCertification";
import { useHelmetHeader } from "../../hooks/custom-hooks/useHelmetHeader";

export type Report = {
  _id: string;
  resultId: string;
  studyPeriod: string;
  studyMethod: string;
  comment: string;
  postTime: string;
  seazonName: string;
  morning1Score: number;
  morning2Score: number;
  afternoon1Score: number;
  afternoon2Score: number;
};

const Report = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const { metaProp } = useHelmetHeader();

  const [reports, setReports] = useState<GetReportResponse[]>([]);

  const getReportContext = useContext(GetReportContext);

  const certificationId = query?.get("pageId");
  const { certifications } = useCertification();
  const targetCertification = certifications?.filter(
    (c) => c.certificationId === certificationId
  )[0];

  const sortReports = (reports: GetReportResponse[]) => {
    if (!reports) {
      return [];
    }
    return reports.sort((a, b) => {
      const beforeDate = new Date(a.postTime);
      const afterDate = new Date(b.postTime);

      return beforeDate.getTime() - afterDate.getTime();
    });
  };

  useEffect(() => {
    const params: GetReportParams = {
      pageId: certificationId,
    };
    getReportContext?.refetch?.(params);
  }, []);

  useEffect(() => {
    const data = getReportContext?.state?.data;

    if (data == null) {
      return;
    }
    setReports(sortReports(data));
  }, [getReportContext?.state?.data]);

  return (
    <div className={styles.reportsContainer}>
      {metaProp && (
        <Helmet
          title={metaProp.title}
          meta={[{ name: "description", content: metaProp.description }]}
        />
      )}
      <HeaderLayout
        title={createTitle(targetCertification ?? null)}
        backgroundColor="#FFFFFF"
        color="#333"
        textAlign="center"
        isBackIcon={true}
        isMenu={true}
      >
        <ReportLayout reports={reports}></ReportLayout>
      </HeaderLayout>
    </div>
  );
};

export default Report;
