import { Button } from "@mui/material";
import styles from "./ErrorHandler.module.scss";
import { ReactNode, createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ErrorResponse, ErrorAction } from "../../hooks/reducer/state";
import { isAxiosError } from "axios";

export type ChangeErrorHandlerState = (flag: boolean) => void;

export const ErrorHandlerContext = createContext<{
  handleError: (hasError: boolean, error?: unknown) => void;
  hasError: boolean;
  messages: ErrorResponse[];
}>({
  handleError: (hasError: boolean, error?: unknown) => {},
  hasError: false,
  messages: [],
});

export const ErrorHandlerProvider = ({ children }: { children: ReactNode }) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [messages, setMessages] = useState<ErrorResponse[]>([]);
  const navigate = useNavigate();

  const handleError = (hasError: boolean, error?: unknown) => {
    if (isAxiosError<ErrorAction>(error)) {
      if (error?.response?.status === 401) {
        setHasError(false);
        navigate("/login");
        return;
      }
    }

    const errorMessage = (() => {
      if (isAxiosError<ErrorAction>(error)) {
        const errorResponse = error?.response?.data;
        if (errorResponse != null) {
          return [errorResponse] as unknown as string[];
        }
      }
      return ["エラーが発生しました。"];
    })();

    setHasError(hasError);
    setMessages(errorMessage);
  };

  return (
    <ErrorHandlerContext.Provider value={{ handleError, hasError, messages }}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};

const ErrorHandler = () => {
  const { handleError, hasError, messages } = useContext(ErrorHandlerContext);

  const close = () => {
    handleError(false);
  };
  return (
    <>
      {hasError && (
        <div className={styles.container}>
          <div className={styles.messageContainer}>
            <div className={styles.message}>
              {messages.map((m) => (
                <div>{m}</div>
              ))}
            </div>
            <Button
              variant="contained"
              className={styles.submitButton}
              color="error"
              onClick={close}
            >
              閉じる
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorHandler;
