// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading_container__9P-Zi {
  width: 100vw;
  height: 100vh;
  z-index: 99999999999999;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  max-width: 800px;
  margin: auto;
  transform: translate(-50%, 0);
  left: 50%;
}

.Loading_loader__uNHht {
  top: 43%;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/loading/Loading.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,0CAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,6BAAA;EACA,SAAA;AACF;;AAEA;EACE,QAAA;EACA,cAAA;AACF","sourcesContent":[".container {\n  width: 100vw;\n  height: 100vh;\n  z-index: 99999999999999;\n  background-color: rgba(255, 255, 255, 0.5);\n  position: fixed;\n  max-width: 800px;\n  margin: auto;\n  transform: translate(-50%, 0);\n  left: 50%;\n}\n\n.loader {\n  top: 43%;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Loading_container__9P-Zi`,
	"loader": `Loading_loader__uNHht`
};
export default ___CSS_LOADER_EXPORT___;
