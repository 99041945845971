// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Certification_contentWrapper__bsyzd {
  padding: 0 1rem 5rem;
  max-width: 800px;
  margin: auto;
}

.Certification_titleWrapper__Hygw8 {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 800;
  margin-top: 2rem;
}

.Certification_message__\\+YLvB {
  font-size: 1.6rem;
  margin-top: 10px;
}

.Certification_homeWrapper__FaGVU {
  height: 100%;
  overflow-y: auto;
}

.Certification_button__1ZmtY {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.Certification_reportButton__NC6Mh {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.Certification_seazonArea__D8DpL {
  margin-bottom: 2rem;
}
.Certification_seazonArea__D8DpL .Certification_seazonName__Wy6xT {
  width: 80%;
  margin: auto;
  font-size: 1rem;
  color: rgb(187, 143, 86);
}
.Certification_seazonArea__D8DpL .Certification_buttonArea__rYTNx {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/certification/Certification.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;AACF;;AACA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;AAEF;;AACA;EAGE,mBAAA;AAAF;AACE;EACE,UAAA;EACA,YAAA;EACA,eAAA;EACA,wBAAA;AACJ;AACE;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;AACJ","sourcesContent":[".contentWrapper {\n  padding: 0 1rem 5rem;\n  max-width: 800px;\n  margin: auto;\n}\n\n.titleWrapper {\n  text-align: center;\n  font-size: 1.2rem;\n  font-weight: 800;\n  margin-top: 2rem;\n}\n\n.message {\n  font-size: 1.6rem;\n  margin-top: 10px;\n}\n\n.homeWrapper {\n  height: 100%;\n  overflow-y: auto;\n}\n\n.button {\n  display: flex;\n  justify-content: center;\n  margin-top: 2rem;\n}\n.reportButton {\n  display: flex;\n  justify-content: center;\n  margin-top: 1rem;\n}\n\n.seazonArea {\n  // border-radius: 10px;\n  // border: 1px solid #50514f;\n  margin-bottom: 2rem;\n  .seazonName {\n    width: 80%;\n    margin: auto;\n    font-size: 1rem;\n    color: rgb(187, 143, 86);\n  }\n  .buttonArea {\n    display: flex;\n    justify-content: center;\n    margin-top: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": `Certification_contentWrapper__bsyzd`,
	"titleWrapper": `Certification_titleWrapper__Hygw8`,
	"message": `Certification_message__+YLvB`,
	"homeWrapper": `Certification_homeWrapper__FaGVU`,
	"button": `Certification_button__1ZmtY`,
	"reportButton": `Certification_reportButton__NC6Mh`,
	"seazonArea": `Certification_seazonArea__D8DpL`,
	"seazonName": `Certification_seazonName__Wy6xT`,
	"buttonArea": `Certification_buttonArea__rYTNx`
};
export default ___CSS_LOADER_EXPORT___;
