import {
  Dispatch,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { Action, ErrorResponse, State, createInitialState } from "./state";
import {
  ChangeLoadingState,
  LoadingContext,
} from "../../molecules/loading/Loading";
import axios, { isAxiosError } from "axios";
import {
  ChangeErrorHandlerState,
  ErrorHandlerContext,
} from "../../molecules/error-handler/ErrorHandler";
import { Seazon } from "../../pages/certification/Certification";

export type GetCertification = {
  certificationId: string;
  certificationName: string;
  seazon: Seazon[];
};

type ContextType<T> = {
  state: State<T>;
  dispatch?: Dispatch<Action<T>>;
  refetch?: (params?: GetCertificationParams) => State<T>;
  resetState?: () => void;
};

const reducer = (
  state: State<GetCertification>,
  action: Action<GetCertification>
): State<GetCertification> => {
  switch (action.type) {
    case "processing":
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case "success":
      return {
        status: action.type,
        data: action.response,
        error: null,
      };
    case "error":
      return {
        status: action.type,
        data: null,
        error: action.error,
      };
    case "reset":
      return {
        status: null,
        data: null,
        error: null,
      };
  }
};

export const initialState = createInitialState<GetCertification>();

export const GetCertificationContext = createContext<
  ContextType<GetCertification>
>({
  state: initialState,
});

export type GetCertificationParams = {
  pageId: string | null;
  seazon?: string | null;
};

export const getCertification = (
  context: ContextType<GetCertification>,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void,
  params?: GetCertificationParams
) => {
  const url = process.env.REACT_APP_SERVER_PATH + "/certification";

  if (context.dispatch == null) {
    return context.state;
  }

  context.dispatch({ type: "processing", response: null, error: null });

  (async () => {
    try {
      const response = await axios.get(url, {
        params,
      });

      if (context == null || context.dispatch == null) {
        return;
      }

      context.dispatch({
        type: "success",
        response: response.data,
        error: null,
      });
    } catch (error) {
      handleError(true, error);
    }
  })();
  return context.state;
};

export function FetchGetCertificationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const refetch = useCallback(
    (params?: GetCertificationParams) =>
      getCertification(
        { state, dispatch },
        changeLoadingState,
        handleError,
        params
      ),
    []
  );

  const resetState = () => {
    dispatch({ type: "reset", response: null, error: null });
  };

  return (
    <GetCertificationContext.Provider
      value={{ state, dispatch, refetch, resetState }}
    >
      {children}
    </GetCertificationContext.Provider>
  );
}
