import {
  Dispatch,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { Action, State, createInitialState } from "./state";
import axios from "axios";
import {
  ChangeErrorHandlerState,
  ErrorHandlerContext,
} from "../../molecules/error-handler/ErrorHandler";

type AnswerMorning = {
  no: number;
  answer: string;
};

export type GetAnswerMorningResponse = {
  no: number;
  answerA: number;
  answerB: number;
  answerC: number;
  answerD: number;
};

type PostAnswerMorning = {
  pageId: string;
  seazon: string;
  answers: AnswerMorning[];
};

type GetAnswerMorningParams = {
  pageId: string | null;
  seazon: string | null;
};

type ContextType<T> = {
  state: State<T>;
  dispatch?: Dispatch<Action<T>>;
  refetch?: (params: GetAnswerMorningParams) => State<T>;
  post?: (request: T) => State<T>;
};

const getReducer = (
  state: State<GetAnswerMorningResponse>,
  action: Action<GetAnswerMorningResponse>
): State<GetAnswerMorningResponse> => {
  switch (action.type) {
    case "processing":
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case "success":
      return {
        status: action.type,
        data: action.response,
        error: null,
      };
    case "error":
      return {
        status: action.type,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
};

const postReducer = (
  state: State<PostAnswerMorning>,
  action: Action<PostAnswerMorning>
): State<PostAnswerMorning> => {
  switch (action.type) {
    case "processing":
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case "success":
      return {
        status: action.type,
        data: action.response,
        error: null,
      };
    case "error":
      return {
        status: action.type,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const initialGetState = createInitialState<GetAnswerMorningResponse>();
export const initialPostState = createInitialState<PostAnswerMorning>();

export const GetAnswerMorningContext = createContext<
  ContextType<GetAnswerMorningResponse>
>({
  state: initialGetState,
});

export const PostAnswerMorningContext = createContext<
  ContextType<PostAnswerMorning>
>({
  state: initialPostState,
});

export const getAnswerMorning = (
  context: ContextType<GetAnswerMorningResponse>,
  params: GetAnswerMorningParams,
  handleError: (hasError: boolean, error?: unknown) => void
) => {
  const url = process.env.REACT_APP_SERVER_PATH + "/answerMorning";

  if (context.dispatch == null) {
    return context.state;
  }

  context.dispatch({ type: "processing", response: null, error: null });

  (async () => {
    try {
      const response = await axios.get(url, {
        params,
      });
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: "success",
        response: response.data,
        error: null,
      });
    } catch (error) {
      handleError(true, error);
    }
  })();
  return context.state;
};

export const postAnswerMorning = (
  context: ContextType<PostAnswerMorning>,
  params: PostAnswerMorning,
  handleError: (hasError: boolean, error?: unknown) => void
) => {
  const url = process.env.REACT_APP_SERVER_PATH + "/answerMorning";

  if (context.dispatch == null) {
    return context.state;
  }

  context.dispatch({ type: "processing", response: null, error: null });

  (async () => {
    try {
      const response = await axios.post(url, {
        pageId: params.pageId,
        seazon: params.seazon,
        answers: params.answers,
      });
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: "success",
        response: response.data,
        error: null,
      });
    } catch (error) {
      handleError(true, error);
    }
  })();
  return context.state;
};

export function GetAnswerMorningProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer(getReducer, initialGetState);
  const { handleError } = useContext(ErrorHandlerContext);

  const refetch = useCallback(
    (params: GetAnswerMorningParams) =>
      getAnswerMorning({ state, dispatch }, params, handleError),
    []
  );

  return (
    <GetAnswerMorningContext.Provider value={{ state, dispatch, refetch }}>
      {children}
    </GetAnswerMorningContext.Provider>
  );
}

export function PostAnswerMorningProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer(postReducer, initialPostState);
  const { handleError } = useContext(ErrorHandlerContext);

  const post = useCallback(
    (params: PostAnswerMorning) =>
      postAnswerMorning({ state, dispatch }, params, handleError),
    []
  );

  return (
    <PostAnswerMorningContext.Provider value={{ state, dispatch, post }}>
      {children}
    </PostAnswerMorningContext.Provider>
  );
}
