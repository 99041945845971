// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Chat_chatContainer__NJYEv {
  background-color: rgb(243, 227, 213);
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/chat-page/Chat.module.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,YAAA;AACF","sourcesContent":[".chatContainer {\n  background-color: rgb(243, 227, 213);\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatContainer": `Chat_chatContainer__NJYEv`
};
export default ___CSS_LOADER_EXPORT___;
