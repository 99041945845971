import { ReactNode, useEffect, useRef } from "react";
import styles from "./ReportLayout.module.scss";
import { Report } from "../../pages/report-page/Report";
import ReportMessage from "../report-message/ReportMessage";

interface ReportLayoutProps {
  reports: Report[];
}

const ReportLayout = (props: ReportLayoutProps) => {
  return (
    <>
      <div className={styles.reportWrapper}>
        <ReportMessage reports={props.reports} />
      </div>
    </>
  );
};

export default ReportLayout;
