// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportLayout_reportWrapper__Y92Vu {
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  height: calc(100% - 4rem);
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/organism/report-layout/ReportLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,sBAAA;EACA,aAAA;EACA,yBAAA;EACA,sBAAA;AACF","sourcesContent":[".reportWrapper {\n  box-sizing: border-box;\n  flex-direction: column;\n  display: flex;\n  height: calc(100% - 4rem);\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reportWrapper": `ReportLayout_reportWrapper__Y92Vu`
};
export default ___CSS_LOADER_EXPORT___;
