import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoadingContext } from "../../molecules/loading/Loading";
import {
  GetChatMassageResponse,
  GetChatMessageContext,
  GetMessageParams,
} from "../../hooks/reducer/ChatReducer";
import styles from "./Chat.module.scss";
import { createTitle } from "../../utils/header-util";
import { Helmet } from "react-helmet";
import { MetaData, metaForHelmet } from "../../../constant/meta";
import ChatLayout from "../../organism/chat-layout/ChatLayout";
import HeaderLayout from "../../organism/header-layout/HeaderLayout";
import { useCertification } from "../../hooks/custom-hooks/useCertification";
import { useHelmetHeader } from "../../hooks/custom-hooks/useHelmetHeader";

export type Message = {
  _id: string;
  messageId: number;
  message: string;
  replyId?: string;
  replyMessage?: string;
  createTime: string;
};

const Chat = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const { metaProp } = useHelmetHeader();

  const [messages, setMessages] = useState<GetChatMassageResponse[]>([]);
  const [seazonId, setSeazonId] = useState<string | null>(null);
  const getChatMessageContext = useContext(GetChatMessageContext);
  const { loadingState, changeLoadingState } = useContext(LoadingContext);

  // collectionへの登録APIに用いる予定
  const certificationId = query?.get("pageId");
  const { certifications } = useCertification();
  const targetCertification = certifications?.filter(
    (c) => c.certificationId === certificationId
  )[0];
  const sortMessages = (messages: GetChatMassageResponse[]) => {
    if (!messages) {
      return [];
    }
    return messages.sort((a, b) => {
      const beforeDate = new Date(a.createTime);
      const afterDate = new Date(b.createTime);

      return beforeDate.getTime() - afterDate.getTime();
    });
  };

  useEffect(() => {
    const params: GetMessageParams = {
      pageId: certificationId,
    };

    getChatMessageContext?.refetch?.(params);
  }, []);

  useEffect(() => {
    const data = getChatMessageContext?.state?.data;

    if (data == null) {
      return;
    }
    setMessages(sortMessages(data));
    changeLoadingState(false);
  }, [getChatMessageContext?.state?.data]);

  return (
    <div className={styles.chatContainer}>
      {metaProp && (
        <Helmet
          title={metaProp.title}
          meta={[{ name: "description", content: metaProp.description }]}
        />
      )}
      <HeaderLayout
        title={createTitle(targetCertification ?? null)}
        backgroundColor="#FFFFFF"
        color="#333"
        textAlign="center"
        isBackIcon={true}
        isMenu={true}
      >
        <ChatLayout messages={messages}></ChatLayout>
      </HeaderLayout>
    </div>
  );
};

export default Chat;
