// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AnswerAfternoonFormPage_container__MAobP {
  width: 100%;
  height: 100%;
}

.AnswerAfternoonFormPage_submit__atrQP {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.AnswerAfternoonFormPage_formContainer__r5kSu {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/answer-afternoon-page/AnswerAfternoonFormPage.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AACA;EACE,eAAA;EACA,aAAA;EACA,uBAAA;AAEF;;AAAA;EACE,gBAAA;AAGF","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n}\n.submit {\n  padding: 10px 0;\n  display: flex;\n  justify-content: center;\n}\n.formContainer {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AnswerAfternoonFormPage_container__MAobP`,
	"submit": `AnswerAfternoonFormPage_submit__atrQP`,
	"formContainer": `AnswerAfternoonFormPage_formContainer__r5kSu`
};
export default ___CSS_LOADER_EXPORT___;
