import { CertificationType } from "../pages/certification/Certification";

export const createTitle = (
  certification: CertificationType | null,
  seazonId?: string | null
) => {
  // 本当はオプショナルで受け取りたくない
  if (certification == null) {
    return "";
  }

  if (seazonId == null) {
    return certification?.certificationName;
  }
  const year = certification?.seazon.filter((seazon) => {
    // TODO：絶対に直したい
    return seazon.seazonId == seazonId;
  })[0];

  return certification?.certificationName + " " + year?.seazonName;
};
