import { ReactNode } from "react";
import { FetchGetCertificationProvider } from "../reducer/CertificationReducer";
import { DeleteAnswerAfternoonProvider } from "../reducer/AnswerAfternoonReducer";
import {
  PostAnswerMorningProvider,
  GetAnswerMorningProvider,
} from "../reducer/AnswerMorningReducer";
import {
  FetchAnswerAfternoonProvider,
  PostAnswerAfternoonProvider,
} from "../reducer/AnswerAfternoonReducer";
import {
  FetchChatMessageProvider,
  FetchPostChatMessageProvider,
} from "../reducer/ChatReducer";
import {
  FetchReportProvider,
  FetchPostReportProvider,
} from "../reducer/ReportReducer";

export const AppProvider = ({ children }: { children: ReactNode }) => {
  return (
    <FetchReportProvider>
      <FetchPostReportProvider>
        <FetchChatMessageProvider>
          <FetchPostChatMessageProvider>
            <FetchGetCertificationProvider>
              <PostAnswerMorningProvider>
                <GetAnswerMorningProvider>
                  <PostAnswerAfternoonProvider>
                    <FetchAnswerAfternoonProvider>
                      <DeleteAnswerAfternoonProvider>
                        {children}
                      </DeleteAnswerAfternoonProvider>
                    </FetchAnswerAfternoonProvider>
                  </PostAnswerAfternoonProvider>
                </GetAnswerMorningProvider>
              </PostAnswerMorningProvider>
            </FetchGetCertificationProvider>
          </FetchPostChatMessageProvider>
        </FetchChatMessageProvider>
      </FetchPostReportProvider>
    </FetchReportProvider>
  );
};
