// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Error_container__o\\+Rxb {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Error_container__o\\+Rxb .Error_message__lnAoE {
  font-size: 1.2rem;
  font-weight: 800;
  margin-top: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/error/Error.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AAAE;EACE,iBAAA;EACA,gBAAA;EACA,gBAAA;AAEJ","sourcesContent":[".container {\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  .message {\n    font-size: 1.2rem;\n    font-weight: 800;\n    margin-top: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Error_container__o+Rxb`,
	"message": `Error_message__lnAoE`
};
export default ___CSS_LOADER_EXPORT___;
