// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  padding: 16px 24px;
  font-size: 1.2rem;
  font-weight: 700;
  border: none;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0a0d10;
  min-height: 47px;
}

.SimpleButton_orange__2qYer {
  background-color: #fabf00;
}

.SimpleButton_redOrange__2jz25 {
  background-color: #f27400;
  color: white;
}

.SimpleButton_transparentRedOrange__7AOtb {
  background-color: transparent;
  color: #f27400;
  border: #f27400 2px solid;
}

.SimpleButton_black__gSYtN {
  background-color: #444444;
  color: white;
}

.SimpleButton_white__DyP6i {
  background-color: #fff;
  color: #000;
}

.SimpleButton_darkGray__8\\+CMZ {
  background-color: #444444;
  color: #fff;
}

.SimpleButton_leftIcon__jNdcp {
  margin-right: 1rem;
}

.SimpleButton_rightIcon__NVqYv {
  margin-left: 1rem;
}

.SimpleButton_leftIcon__jNdcp,
.SimpleButton_rightIcon__NVqYv {
  height: 1rem;
}

.SimpleButton_disabled__6n6Ze {
  opacity: 0.4;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/button/SimpleButton.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;AACF;;AACA;EACE,yBAAA;AAEF;;AACA;EACE,yBAAA;EACA,YAAA;AAEF;;AACA;EACE,6BAAA;EACA,cAAA;EACA,yBAAA;AAEF;;AACA;EACE,yBAAA;EACA,YAAA;AAEF;;AACA;EACE,sBAAA;EACA,WAAA;AAEF;;AACA;EACE,yBAAA;EACA,WAAA;AAEF;;AACA;EACE,kBAAA;AAEF;;AAAA;EACE,iBAAA;AAGF;;AAAA;;EAEE,YAAA;AAGF;;AAAA;EACE,YAAA;AAGF","sourcesContent":["button {\n  padding: 16px 24px;\n  font-size: 1.2rem;\n  font-weight: 700;\n  border: none;\n  border-radius: 1rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #0a0d10;\n  min-height: 47px;\n}\n.orange {\n  background-color: #fabf00;\n}\n\n.redOrange {\n  background-color: #f27400;\n  color: white;\n}\n\n.transparentRedOrange {\n  background-color: transparent;\n  color: #f27400;\n  border: #f27400 2px solid;\n}\n\n.black {\n  background-color: #444444;\n  color: white;\n}\n\n.white {\n  background-color: #fff;\n  color: #000;\n}\n\n.darkGray {\n  background-color: #444444;\n  color: #fff;\n}\n\n.leftIcon {\n  margin-right: 1rem;\n}\n.rightIcon {\n  margin-left: 1rem;\n}\n\n.leftIcon,\n.rightIcon {\n  height: 1rem;\n}\n\n.disabled {\n  opacity: 0.4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orange": `SimpleButton_orange__2qYer`,
	"redOrange": `SimpleButton_redOrange__2jz25`,
	"transparentRedOrange": `SimpleButton_transparentRedOrange__7AOtb`,
	"black": `SimpleButton_black__gSYtN`,
	"white": `SimpleButton_white__DyP6i`,
	"darkGray": `SimpleButton_darkGray__8+CMZ`,
	"leftIcon": `SimpleButton_leftIcon__jNdcp`,
	"rightIcon": `SimpleButton_rightIcon__NVqYv`,
	"disabled": `SimpleButton_disabled__6n6Ze`
};
export default ___CSS_LOADER_EXPORT___;
