import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import styles from "./ReportForm.module.scss";
import {
  PostReportRequest,
  PostReportContext,
} from "../../hooks/reducer/ReportReducer";
import { useLocation } from "react-router-dom";
import Pulldown from "../../atoms/pulldown/Pulldown";
import { result } from "./ReportForm-const";
import Textbox, {
  TextFormResult,
  NumberFormResult,
} from "../../atoms/textbox/Textbox";
import SimpleButton from "../../atoms/button/SimpleButton";
import SimpleModal from "../../organism/simple-modal/SimpleModal";
import { useAtom } from "jotai";
import { modalState } from "../../molecules/modal-wrapper/ModalWrapper";
import { useCertification } from "../../hooks/custom-hooks/useCertification";

type ReportFormContent = {
  resultId: string | null;
  studyPeriod: string | null;
  studyMethod: string | null;
  comment: string | null;
  seazonName: string | null;
  morning1Score: number | null;
  morning2Score: number | null;
  afternoon1Score: number | null;
  afternoon2Score: number | null;
};

const ReportForm = () => {
  const navigate = useNavigate();

  const studyMethodPlaceHolder = `参考にしたテキストやサイトを記入してください。`;
  const studyPeriodPlaceHolder = `50時間〜60時間`;
  const commentPlaceHolder = `設問の〇〇が難しかった。〇〇の勉強方法が有意義だった。など`;

  const initialReportForm: ReportFormContent = {
    resultId: null,
    studyPeriod: null,
    studyMethod: null,
    comment: null,
    seazonName: null,
    morning1Score: null,
    morning2Score: null,
    afternoon1Score: null,
    afternoon2Score: null,
  };
  const [disable, setDisable] = useState(true);
  const [reportFormContent, setReportFormContent] = useState(initialReportForm);
  const [, setState] = useAtom(modalState);

  const handleTextForm = (e: TextFormResult) => {
    setReportFormContent({ ...reportFormContent, [e.name]: e.value });
  };

  const handleNumberForm = (e: NumberFormResult) => {
    setReportFormContent({ ...reportFormContent, [e.name]: e.value });
  };
  const postReportContext = useContext(PostReportContext);

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const certificationId = query?.get("pageId");

  const checkFormValidataion = () => {
    return (
      reportFormContent.resultId == null || reportFormContent.seazonName == null
    );
  };
  const { certifications } = useCertification();
  const certification = certifications?.find(
    (c) => c.certificationId === certificationId
  );
  const seazonItems = certification?.seazon?.map((s) => ({
    name: s.seazonName,
    value: s.seazonName,
  })) || [{ name: "", value: "" }];

  useEffect(() => {
    setDisable(checkFormValidataion());
  }, [reportFormContent]);

  useEffect(() => {
    const data = postReportContext?.state?.data;

    if (data == null) {
      return;
    }

    if (postReportContext.state.status === "success") {
      setState(true);
      postReportContext?.dispatch?.({
        type: "processing",
        response: null,
        error: null,
      });
    }
  }, [postReportContext]);

  const submit = async () => {
    const params: PostReportRequest = {
      pageId: certificationId,
      comment: reportFormContent.comment,
      studyMethod: reportFormContent.studyMethod,
      studyPeriod: reportFormContent.studyPeriod,
      resultId: reportFormContent.resultId,
      seazonName: reportFormContent.seazonName,
      morning1Score: reportFormContent.morning1Score,
      morning2Score: reportFormContent.morning2Score,
      afternoon1Score: reportFormContent.afternoon1Score,
      afternoon2Score: reportFormContent.afternoon2Score,
    };
    await postReportContext?.post?.(params);
  };

  const handleClose = () => {
    navigate(`/report?pageId=${certificationId}`);
  };

  return (
    <div className={styles.formWrapper}>
      <div className={styles.inputLine}>
        <label className={styles.inputTitleRequired}>試験時期</label>
        <Pulldown
          id={""}
          name={"seazonName"}
          title=""
          items={seazonItems}
          handlePulldownChange={handleTextForm}
        ></Pulldown>
      </div>
      <div className={styles.inputLine}>
        <label className={styles.inputTitleRequired}>合否</label>
        <Pulldown
          id={""}
          name={"resultId"}
          title=""
          items={result}
          handlePulldownChange={handleTextForm}
        ></Pulldown>
      </div>
      <div className={styles.inputLineScore}>
        {certificationId === "OUYOU" ? (
          <>
            <div className={styles.inputGroup}>
              <div className={styles.inputTitle}>午前</div>
              <div className={styles.textUnit}>
                <Textbox
                  id={"morning1Score"}
                  handleNumberChange={handleNumberForm}
                  rows={1}
                  type={"number"}
                  name={"morning1Score"}
                  // variant={"filled"} こっちのが見た目良い
                ></Textbox>
                <div>点</div>
              </div>
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.inputTitle}>午後</div>
              <div className={styles.textUnit}>
                <Textbox
                  id={"afternoon1Score"}
                  handleNumberChange={handleNumberForm}
                  rows={1}
                  type={"number"}
                  name={"afternoon1Score"}
                  // variant={"filled"} こっちのが見た目良い
                ></Textbox>
                <div>点</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.inputGroup}>
              <div className={styles.inputTitle}>午前I</div>
              <div className={styles.textUnit}>
                <Textbox
                  id={"morning1Score"}
                  handleNumberChange={handleNumberForm}
                  rows={1}
                  type={"number"}
                  name={"morning1Score"}
                  // variant={"filled"} こっちのが見た目良い
                ></Textbox>
                <div>点</div>
              </div>
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.inputTitle}>午前II</div>
              <div className={styles.textUnit}>
                <Textbox
                  id={"morning2Score"}
                  handleNumberChange={handleNumberForm}
                  rows={1}
                  type={"number"}
                  name={"morning2Score"}
                  // variant={"filled"} こっちのが見た目良い
                ></Textbox>
                <div>点</div>
              </div>
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.inputTitle}>午後I</div>
              <div className={styles.textUnit}>
                <Textbox
                  id={"afternoon1Score"}
                  handleNumberChange={handleNumberForm}
                  rows={1}
                  type={"number"}
                  name={"afternoon1Score"}
                  // variant={"filled"} こっちのが見た目良い
                ></Textbox>
                <div>点</div>
              </div>
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.inputTitle}>午後II</div>
              <div className={styles.textUnit}>
                <Textbox
                  id={"afternoon2Score"}
                  handleNumberChange={handleNumberForm}
                  rows={1}
                  type={"number"}
                  name={"afternoon2Score"}
                  // variant={"filled"} こっちのが見た目良い
                ></Textbox>
                <div>点</div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.inputLine}>
        <div className={styles.inputTitle}>勉強方法</div>
        <Textbox
          id={"studyMethod"}
          placeHolder={studyMethodPlaceHolder}
          handleTextboxChange={handleTextForm}
          rows={2}
          type={"text"}
          name={"studyMethod"}
          // variant={"filled"} こっちのが見た目良い
        ></Textbox>
      </div>
      <div className={styles.inputLine}>
        <div className={styles.inputTitle}>勉強期間</div>
        <Textbox
          id={"studyPeriod"}
          placeHolder={studyPeriodPlaceHolder}
          handleTextboxChange={handleTextForm}
          rows={1}
          type={"text"}
          name={"studyPeriod"}
          // variant={"filled"} こっちのが見た目良い
        ></Textbox>
      </div>
      <div className={styles.inputLine}>
        <div className={styles.inputTitleRequired}>感想</div>
        <Textbox
          id={"comment"}
          placeHolder={commentPlaceHolder}
          handleTextboxChange={handleTextForm}
          rows={7}
          type={"text"}
          name={"comment"}
          // variant={"filled"} こっちのが見た目良い
        ></Textbox>
      </div>
      <div className={styles.submit}>
        <SimpleButton
          label="送信"
          width="80%"
          color="darkGray"
          disabled={disable}
          onClick={() => submit()}
        ></SimpleButton>
      </div>
      <SimpleModal
        handleClose={handleClose}
        message="投稿が完了しました。<br/>ご協力ありがとうございました。"
      ></SimpleModal>
    </div>
  );
};

export default ReportForm;
