import { format } from "date-fns";

export const toHHMM = (date: string): string => {
  const newDate = new Date(date);
  return format(newDate, "HH:mm");
};

export const toYYYYMMDDWithSlah = (date: string): string => {
  const newDate = new Date(date);
  return format(newDate, "yyyy/MM/dd");
};
