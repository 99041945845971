import styles from "./AnswerMorningFormPage.module.scss";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { createTitle } from "../../utils/header-util";
import HeaderLayout from "../../organism/header-layout/HeaderLayout";
import AnswerMorningForm from "../../organism/answer-morning-form/AnswerMorningForm";
import { ANSWER_MORNING1 } from "../../../constant/const";
import { useCertification } from "../../hooks/custom-hooks/useCertification";
const AnswerMorningFormPage = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const certificationId = query?.get("pageId");
  const seazonId = query?.get("seazon");
  const level = query?.get("level");
  const { certifications } = useCertification();
  const targetCertification = certifications?.filter(
    (c) => c.certificationId === certificationId
  )[0];
  const [examlevel, setexamlevel] = useState<"MORNING1" | "MORNING2">(
    level === ANSWER_MORNING1 ? "MORNING1" : "MORNING2"
  );

  useEffect(() => {
    if (level === ANSWER_MORNING1) {
      setexamlevel("MORNING1");
    } else {
      setexamlevel("MORNING2");
    }
  }, []);
  const createPath = () => {
    if (examlevel === ANSWER_MORNING1) {
      return `/answer-morning-list?pageId=${certificationId}&seazon=${seazonId}&level=MORNING1`;
    }
    return `/answer-morning-list?pageId=${certificationId}&seazon=${seazonId}`;
  };

  return (
    <div className={styles.answerContainer}>
      <HeaderLayout
        title={createTitle(targetCertification ?? null, seazonId, examlevel)}
        backgroundColor="#FFFFFF"
        color="#333"
        textAlign="center"
        isMenu={true}
        isBackIcon={true}
        seazonId={seazonId}
        path={createPath()}
      >
        <div>
          <AnswerMorningForm examlevel={examlevel} />
        </div>
      </HeaderLayout>
    </div>
  );
};

export default AnswerMorningFormPage;
