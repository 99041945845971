export type ErrorResponse = string;

export type State<T> =
  | initialState
  | ProcessingState
  | SuccessState<T>
  | ErrorState;

type initialState = {
  status: null;
  data: null;
  error: null;
};

type ProcessingState = {
  status: "processing";
  data: null;
  error: null;
};

type SuccessState<T> = {
  status: "success";
  data: T[];
  error: null;
};

type ErrorState = {
  status: "error";
  data: null;
  error: ErrorResponse[];
};

export type Action<T> =
  | ProcessingAction
  | SuccessAction<T>
  | ErrorAction
  | ResetAction;

export type ProcessingAction = {
  type: "processing";
  response: null;
  error: null;
};

export type SuccessAction<T> = {
  type: "success";
  response: T[];
  error: null;
};

export type ErrorAction = {
  type: "error";
  response: null;
  error: ErrorResponse[];
};

export type ResetAction = {
  type: "reset";
  response: null;
  error: null;
};

export const createInitialState = <T>(): State<T> => {
  return {
    status: null,
    data: null,
    error: null,
  };
};
