import { Button } from "@mui/material";
import styles from "./Error.module.scss";

interface ErrorProps {}

const click = () => {
  // しょうがないけどこうするしかなかった、、
  window.location.href = process.env.REACT_APP_HOME + "/certification";
};

const Error = () => {
  return (
    <div className={styles.container}>
      <div className={styles.message}>エラーです！！！</div>
      <div>
        <Button
          variant="contained"
          className={styles.submitButton}
          color="error"
          onClick={click}
        >
          TOPページへ
        </Button>
      </div>
    </div>
  );
};

export default Error;
