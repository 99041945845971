// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AnswerMorningList_container__DYDtc {
  width: 100%;
  height: 100%;
}

.AnswerMorningList_titleContainer__MEBHH {
  font-size: 20px;
  margin-top: 30px;
  text-align: center;
  font-weight: 800;
}

.AnswerMorningList_table__q\\+G4c {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

.AnswerMorningList_answerArea__dpm50 {
  display: flex;
  justify-content: center;
  padding: 0 40px 20px;
  margin-top: 30px;
}

th {
  height: 50px;
  background-color: #7eca0a;
  position: sticky;
  top: 0;
  left: 0;
}

td {
  height: 32px;
}

th,
td {
  width: 50px;
  text-align: center;
}

.AnswerMorningList_probremNo__AbynF {
  width: 100px;
  font-weight: 500;
}
.AnswerMorningList_probremNo__AbynF .AnswerMorningList_reliability__uutjI {
  font-size: 12px;
  background: #ebbc5a;
  border-radius: 10px;
  padding: 3px;
}`, "",{"version":3,"sources":["webpack://./src/components/organism/answer-morning-list/AnswerMorningList.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,yBAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AACA;EACE,aAAA;EACA,uBAAA;EACA,oBAAA;EACA,gBAAA;AAEF;;AACA;EACE,YAAA;EACA,yBAAA;EACA,gBAAA;EACA,MAAA;EACA,OAAA;AAEF;;AACA;EACE,YAAA;AAEF;;AACA;;EAEE,WAAA;EACA,kBAAA;AAEF;;AACA;EACE,YAAA;EACA,gBAAA;AAEF;AADE;EACE,eAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;AAGJ","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n}\n\n.titleContainer {\n  font-size: 20px;\n  margin-top: 30px;\n  text-align: center;\n  font-weight: 800;\n}\n\n.table {\n  border-collapse: collapse;\n  border-spacing: 0;\n  table-layout: fixed;\n}\n.answerArea {\n  display: flex;\n  justify-content: center;\n  padding: 0 40px 20px;\n  margin-top: 30px;\n}\n\nth {\n  height: 50px;\n  background-color: #7eca0a;\n  position: sticky;\n  top: 0;\n  left: 0;\n}\n\ntd {\n  height: 32px;\n}\n\nth,\ntd {\n  width: 50px;\n  text-align: center;\n}\n\n.probremNo {\n  width: 100px;\n  font-weight: 500;\n  .reliability {\n    font-size: 12px;\n    background: #ebbc5a;\n    border-radius: 10px;\n    padding: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AnswerMorningList_container__DYDtc`,
	"titleContainer": `AnswerMorningList_titleContainer__MEBHH`,
	"table": `AnswerMorningList_table__q+G4c`,
	"answerArea": `AnswerMorningList_answerArea__dpm50`,
	"probremNo": `AnswerMorningList_probremNo__AbynF`,
	"reliability": `AnswerMorningList_reliability__uutjI`
};
export default ___CSS_LOADER_EXPORT___;
