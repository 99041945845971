import ModalWrapper, {
  modalState,
} from "../../molecules/modal-wrapper/ModalWrapper";
import styles from "./AnswerDeleteModal.module.scss";
import Textbox, { TextFormResult } from "../../atoms/textbox/Textbox";
import { atom, useAtom } from "jotai";
import { useContext, useEffect, useState } from "react";
import {
  DeleteAnswerAfternoon,
  DeleteAnswerAfternoonContext,
} from "../../hooks/reducer/AnswerAfternoonReducer";

type AnswerDeleteModalProps = {
  id: string;
};

const AnswerDeleteModal = (props: AnswerDeleteModalProps) => {
  const [password, setPassword] = useState<string | null>(null);
  const [disabled, setDisabled] = useState<boolean>(true);
  const deleteAnswerAfternoonContext = useContext(DeleteAnswerAfternoonContext);
  const [state, setState] = useAtom(modalState);

  const handleChange = (e: TextFormResult) => {
    setPassword(e.value);
  };

  useEffect(() => {
    // null、空、半角英数以外禁止
    setDisabled(
      password == null ||
        password.length === 0 ||
        !password.match(/^[A-Za-z0-9]*$/)
    );
  }, [password]);

  useEffect(() => {
    if (deleteAnswerAfternoonContext.state.status === "success") {
      deleteAnswerAfternoonContext?.dispatch?.({
        type: "processing",
        response: null,
        error: null,
      });
      setState(false);
    }
  }, [deleteAnswerAfternoonContext.state]);

  const submit = () => {
    const params: DeleteAnswerAfternoon = {
      id: props.id,
      password,
    };

    deleteAnswerAfternoonContext?.deleteAnswer?.(params);
  };

  return (
    <>
      <ModalWrapper submit={submit} disabled={disabled}>
        <div className={styles.container}>
          <div className={styles.message}>設定したパスワードで削除</div>
          <div className={styles.textbox}>
            <Textbox
              name="deleteAnswer"
              id="deleteAnswer"
              handleTextboxChange={handleChange}
            ></Textbox>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default AnswerDeleteModal;
