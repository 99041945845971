// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalWrapper_overlay__jJdPZ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.ModalWrapper_overlay__jJdPZ .ModalWrapper_container__SU52r {
  position: fixed;
  z-index: 99999999;
  width: 16rem;
  height: 16rem;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #333;
  padding: 2rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
.ModalWrapper_overlay__jJdPZ .ModalWrapper_container__SU52r .ModalWrapper_contentArea__CumV6 {
  font-size: 1rem;
  font-weight: bolder;
  line-height: 1.24;
}
.ModalWrapper_overlay__jJdPZ .ModalWrapper_container__SU52r .ModalWrapper_footer__NQPBY {
  display: flex;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/modal-wrapper/ModalWrapper.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,aAAA;AACF;AAAE;EACE,eAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,kBAAA;AAEJ;AADI;EACE,eAAA;EACA,mBAAA;EACA,iBAAA;AAGN;AADI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAGN","sourcesContent":[".overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 1000;\n  .container {\n    position: fixed;\n    z-index: 99999999;\n    width: 16rem;\n    height: 16rem;\n    background: white;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    border: 1px solid #333;\n    padding: 2rem;\n    border-radius: 2rem;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    text-align: center;\n    .contentArea {\n      font-size: 1rem;\n      font-weight: bolder;\n      line-height: 1.24;\n    }\n    .footer {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `ModalWrapper_overlay__jJdPZ`,
	"container": `ModalWrapper_container__SU52r`,
	"contentArea": `ModalWrapper_contentArea__CumV6`,
	"footer": `ModalWrapper_footer__NQPBY`
};
export default ___CSS_LOADER_EXPORT___;
