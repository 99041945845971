// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CertificationAccordion_button__SfIaN {
  padding: 0.5rem;
}

.CertificationAccordion_title__tUCfz {
  font-size: 1rem;
}

.CertificationAccordion_answerTitle__0pP7o {
  width: 4rem;
}

.CertificationAccordion_answerArea__7yK9t {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/organism/certification-accordion/CertificationAccordion.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AACF","sourcesContent":[".button {\n  padding: 0.5rem;\n}\n\n.title {\n  font-size: 1rem;\n}\n\n.answerTitle {\n  width: 4rem;\n}\n\n.answerArea {\n  padding: 0.5rem;\n  display: flex;\n  align-items: center;\n  gap: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `CertificationAccordion_button__SfIaN`,
	"title": `CertificationAccordion_title__tUCfz`,
	"answerTitle": `CertificationAccordion_answerTitle__0pP7o`,
	"answerArea": `CertificationAccordion_answerArea__7yK9t`
};
export default ___CSS_LOADER_EXPORT___;
