import { ReactNode } from "react";
import styles from "./ModalWrapper.module.scss";
import Button from "@mui/material/Button";
import { atom, useAtom } from "jotai";
import { CSSProperties } from "react";

type ModalWrapperProps = {
  children: ReactNode;
  width?: string;
  height?: string;
  disabled?: boolean;
  submit: () => void;
};

export const modalState = atom(false);

const ModalWrapper = (props: ModalWrapperProps) => {
  const [state, setState] = useAtom(modalState);

  const modalStyle = (): CSSProperties => {
    const style = {};
    if (props.height != null) {
      return { height: props.height };
    }

    if (props.width != null) {
      return { width: props.width };
    }

    return {};
  };

  const onClick = () => {
    props.submit();
  };

  return (
    <>
      {state && (
        <div className={styles.overlay}>
          <div className={styles.container} style={modalStyle()}>
            <div className={styles.contentArea}>{props.children}</div>
            <div className={styles.footer}>
              <Button
                variant="contained"
                className={styles.submitButton}
                color="success"
                onClick={() => setState(false)}
              >
                閉じる
              </Button>
              <Button
                variant="contained"
                className={styles.submitButton}
                color="success"
                disabled={props.disabled}
                onClick={onClick}
              >
                送信する
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalWrapper;
