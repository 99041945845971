import { ReactNode, useEffect, useRef } from "react";
import styles from "./ChatLayout.module.scss";
import { Message } from "../../pages/chat-page/Chat";
import ChatMessage from "../chat-messaage/ChatMessage";

interface ChatLayoutProps {
  messages: Message[];
}

const ChatLayout = (props: ChatLayoutProps) => {
  return (
    <>
      <div className={styles.messageWrapper}>
        <ChatMessage messages={props.messages} />
      </div>
    </>
  );
};

export default ChatLayout;
