// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Menu_motion__0FZ28 {
  position: absolute;
  top: 0;
  width: 100%;
}

.Menu_container__63l2q {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  justify-content: center;
  width: 75%;
  margin: auto;
}

.Menu_title__wzcp3 {
  font-size: 26px;
  font-weight: 800;
  text-align: center;
  padding: 30px 30px 10px;
}

.Menu_menu__tk0Wl {
  color: white;
  background-color: rgb(140, 121, 104);
  position: absolute;
  padding-bottom: 3rem;
  z-index: 99999;
  width: 100%;
  top: 0;
}

.Menu_divider__3qhPN {
  border: 0.2px solid white;
  width: 60%;
  margin: auto;
}

.Menu_list__ExgMs {
  padding: 10px;
  font-size: 20px;
  color: white;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.Menu_miniDivider__EoWwt {
  width: 100%;
  margin: auto;
  border: 0.1px solid rgba(255, 255, 255, 0.4);
}

.Menu_menuTitle__7FNoN {
  margin-left: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/menu/Menu.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,uBAAA;EACA,UAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,oCAAA;EACA,kBAAA;EACA,oBAAA;EACA,cAAA;EACA,WAAA;EACA,MAAA;AACF;;AAEA;EACE,yBAAA;EACA,UAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,4CAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".motion {\n  position: absolute;\n  top: 0;\n  width: 100%;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  margin-top: 10px;\n  justify-content: center;\n  width: 75%;\n  margin: auto;\n}\n\n.title {\n  font-size: 26px;\n  font-weight: 800;\n  text-align: center;\n  padding: 30px 30px 10px;\n}\n\n.menu {\n  color: white;\n  background-color: rgb(140, 121, 104);\n  position: absolute;\n  padding-bottom: 3rem;\n  z-index: 99999;\n  width: 100%;\n  top: 0;\n}\n\n.divider {\n  border: 0.2px solid white;\n  width: 60%;\n  margin: auto;\n}\n\n.list {\n  padding: 10px;\n  font-size: 20px;\n  color: white;\n  margin-top: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: start;\n}\n\n.miniDivider {\n  width: 100%;\n  margin: auto;\n  border: 0.1px solid rgba(255, 255, 255, 0.4);\n}\n\n.menuTitle {\n  margin-left: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"motion": `Menu_motion__0FZ28`,
	"container": `Menu_container__63l2q`,
	"title": `Menu_title__wzcp3`,
	"menu": `Menu_menu__tk0Wl`,
	"divider": `Menu_divider__3qhPN`,
	"list": `Menu_list__ExgMs`,
	"miniDivider": `Menu_miniDivider__EoWwt`,
	"menuTitle": `Menu_menuTitle__7FNoN`
};
export default ___CSS_LOADER_EXPORT___;
