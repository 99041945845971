import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AFTERNOON_UNDIVIDED,
  ANSWER_MORNING_EIGHTY,
} from "../../../constant/const";
import ChoiceButton from "../../atoms/choicebutton/Choicebutton";
import Pulldown from "../../atoms/pulldown/Pulldown";
import Textbox, { TextFormResult } from "../../atoms/textbox/Textbox";
import {
  PostAnswerAfternoon,
  PostAnswerAfternoonContext,
} from "../../hooks/reducer/AnswerAfternoonReducer";
import {
  difficulty,
  examStage,
  ouyouSection,
  question,
  section,
  sekisupeSection,
} from "./AnswerAfternoon-const";
import styles from "./AnswerAfternoonForm.module.scss";
import SimpleModal from "../../organism/simple-modal/SimpleModal";
import { useAtom } from "jotai";
import { modalState } from "../../molecules/modal-wrapper/ModalWrapper";
import SimpleButton from "../../atoms/button/SimpleButton";
interface AnswerAfternoonProps {}

type PmAnswerForm = {
  examStage: string | null;
  section: string | null;
  question: string | null;
  answer: string | null;
  difficulty: string | null;
  password: string | null;
  userName: string | null;
};

const AnswerAfternoonForm = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const navigate = useNavigate();

  const certificationId = query?.get("pageId"); //ヘッダ表示用にいる
  const seazonId = query?.get("seazon"); //ヘッダ表示用にいる

  const initialPmAnswerForm: PmAnswerForm = {
    examStage: null,
    section: null,
    question: null,
    answer: null,
    difficulty: null,
    password: null,
    userName: null,
  };
  const [pmAnswerForm, setPmAnswerForm] = useState(initialPmAnswerForm);
  const [disabled, setDisabled] = useState(true);
  const [, setState] = useAtom(modalState);
  const handleForm = (e: TextFormResult) => {
    setPmAnswerForm({ ...pmAnswerForm, [e.name]: e.value });
  };

  const postAnswerAfternoonContext = useContext(PostAnswerAfternoonContext);

  const placeHolder = `（例）
  IPアドレスやサブネットマスクなどのネットワーク設定を自動的に割り当てる。
  
  こんな感じだと何点もらえるかな、、？
  `;

  const submit = () => {
    const params: PostAnswerAfternoon = {
      certificationId: certificationId,
      seazonId: seazonId,
      examStage: pmAnswerForm.examStage,
      section: pmAnswerForm.section,
      question: pmAnswerForm.question,
      answer: pmAnswerForm.answer,
      difficulty: pmAnswerForm.difficulty,
      password: pmAnswerForm.password,
      userName: pmAnswerForm.userName,
    };

    postAnswerAfternoonContext?.post?.(params);
  };

  useEffect(() => {
    if (postAnswerAfternoonContext.state.status === "success") {
      setState(true);
      postAnswerAfternoonContext?.dispatch?.({
        type: "processing",
        response: null,
        error: null,
      });
    }
  }, [postAnswerAfternoonContext.state]);

  useEffect(() => {
    setDisabled(checkFormValidation());
  }, [pmAnswerForm]);

  const checkFormValidation = () => {
    return (
      pmAnswerForm.examStage == null ||
      pmAnswerForm.section == null ||
      pmAnswerForm.difficulty == null ||
      pmAnswerForm.answer == null ||
      pmAnswerForm.answer === "" ||
      pmAnswerForm.password == null ||
      pmAnswerForm.password === "" ||
      !pmAnswerForm.password.match(/^[A-Za-z0-9]*$/) ||
      pmAnswerForm.userName == null ||
      pmAnswerForm.userName === ""
    );
  };

  const createSection = () => {
    if (certificationId === ANSWER_MORNING_EIGHTY) {
      return ouyouSection;
    } else if (certificationId === "SEKISUPE") {
      return sekisupeSection;
    }

    return section;
  };
  const handleClose = () => {
    navigate(
      `/answer-afternoon-list?pageId=${certificationId}&seazon=${seazonId}`
    );
  };

  return (
    <div className={styles.answerContainer}>
      <div className={styles.answerTitile}>解答フォーム</div>
      <div className={styles.formContaierFirst}>
        <Pulldown
          id={"examStage"}
          name={"examStage"}
          title="午後"
          items={
            certificationId && AFTERNOON_UNDIVIDED.includes(certificationId)
              ? examStage.slice(0, 1)
              : examStage
          }
          handlePulldownChange={handleForm}
        ></Pulldown>
        <Pulldown
          id={"section"}
          name={"section"}
          title="大問"
          items={createSection()}
          handlePulldownChange={handleForm}
        ></Pulldown>
        <Pulldown
          id={"question"}
          name={"question"}
          title="設問"
          items={question}
          handlePulldownChange={handleForm}
        ></Pulldown>
      </div>
      <div className={styles.formContaier}>
        <Textbox
          id={"text"}
          placeHolder={placeHolder}
          handleTextboxChange={handleForm}
          rows={10}
          type={"text"}
          label={"解答入力"}
          name={"answer"}
        ></Textbox>
      </div>
      {/* 難化、易化って消す？？ */}
      <div className={styles.formContaier}>
        <ChoiceButton
          id={"difficulty"}
          name={"difficulty"}
          selectItems={difficulty}
          handleChoiceButtonChange={handleForm}
        ></ChoiceButton>
      </div>
      <div className={styles.formContaier}>
        <Textbox
          id={"userName"}
          handleTextboxChange={handleForm}
          type={"text"}
          label={"名前"}
          name={"userName"}
        ></Textbox>
      </div>
      <div className={styles.formContaier}>
        <Textbox
          id={"password"}
          handleTextboxChange={handleForm}
          type={"password"}
          label={"パスワード"}
          name={"password"}
        ></Textbox>
      </div>
      <div className={styles.submit}>
        {/* <Button
          variant="contained"
          className={styles.submitButton}
          color="success"
          disabled={disabled}
          onClick={() => submit()}
        >
          送信
        </Button> */}
        <SimpleButton
          label={"送信"}
          width="50%"
          color="orange"
          disabled={disabled}
          onClick={() => submit()}
        ></SimpleButton>
        <SimpleModal
          handleClose={handleClose}
          message="回答が完了しました。<br/>午後試験お疲れ様でした。"
        ></SimpleModal>
      </div>
    </div>
  );
};

export default AnswerAfternoonForm;
