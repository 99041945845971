import { BrowserRouter } from "react-router-dom";
import Router from "./router/router";
import Loading, {
  LoadingProvider,
} from "./components/molecules/loading/Loading";
import { AppProvider } from "./components/hooks/provider/AppProvider";
import styles from "./App.module.scss";
import ErrorHandler, {
  ErrorHandlerProvider,
} from "./components/molecules/error-handler/ErrorHandler";
import ErrorHandlerPage from "./components/pages/error-handler/ErrorHandler";
import ModalWrapper, {
  modalState,
} from "./components/molecules/modal-wrapper/ModalWrapper";
import { useAtom } from "jotai";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useState } from "react";

function App() {
  const queryClient = new QueryClient();

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <LoadingProvider>
          <Loading />
          <ErrorHandlerProvider>
            <div className={styles.appContainer}>
              <ErrorHandler />
              <ErrorHandlerPage />
              <AppProvider>
                <Router></Router>
              </AppProvider>
            </div>
          </ErrorHandlerProvider>
        </LoadingProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
