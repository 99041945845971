import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { useAtom } from "jotai";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { AFTERNOON_UNDIVIDED } from "../../../constant/const";
import {
  difficultyMapper,
  sectionMapper,
} from "../../../constant/mapper-const";
import { MetaData, metaAnswerAfternoonForHelmet } from "../../../constant/meta";
import logoImage from "../../../static/images/header-icon.png";
import Pulldown from "../../atoms/pulldown/Pulldown";
import { TextFormResult } from "../../atoms/textbox/Textbox";
import {
  FetchAnswerAfternoonContext,
  FetchAnswerAfternoonResponse,
} from "../../hooks/reducer/AnswerAfternoonReducer";
import { GetCertificationContext } from "../../hooks/reducer/CertificationReducer";
import { modalState } from "../../molecules/modal-wrapper/ModalWrapper";
import { filterAnswer } from "../../organism/answer-afternoon-form/AnswerAfternoon-const";
import AnswerDeleteModal from "../../organism/answer-delete-modal/AnswerDeleteModal";
import HeaderLayout from "../../organism/header-layout/HeaderLayout";
import { toHHMM, toYYYYMMDDWithSlah } from "../../utils/date-util";
import { createTitle } from "../../utils/header-util";
import { CertificationType } from "../certification/Certification";
import styles from "./AnswerAfternoonList.module.scss";

interface AnswerAfternoonListProps {}

const AnswerAfternoonList = () => {
  const [examStage, setExamStage] = useState<string>("afternoon1");
  const [aftrenoonAnswers, setAfternoonAnswers] = useState<
    FetchAnswerAfternoonResponse[] | null
  >(null);
  const [displayedAftrenoonAnswers, setDisplayedAftrenoonAnswers] = useState<
    FetchAnswerAfternoonResponse[] | null
  >(null);
  const [selectId, setSelectId] = useState<string | null>(null);
  const navigate = useNavigate();
  const [metaProp, setMetaProp] = useState<MetaData>();

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const certificationId = query?.get("pageId");
  const seazonId = query?.get("seazon");

  const fetchAnswerAfternoonContext = useContext(FetchAnswerAfternoonContext);
  const [state, setState] = useAtom(modalState);

  const [pulldownValue, setPulldownValue] = useState<string>("all");

  useEffect(() => {
    if (certificationId == null || seazonId == null) {
      return;
    }
    const params = {
      certificationId,
      seazonId,
      examStage, // 名前を見直したい
    };

    if (!state) {
      fetchAnswerAfternoonContext?.refetch?.(params);
    }
  }, [examStage, state]);

  useEffect(() => {
    setAfternoonAnswers(fetchAnswerAfternoonContext.state.data);
    setDisplayedAftrenoonAnswers(fetchAnswerAfternoonContext.state.data);
  }, [fetchAnswerAfternoonContext.state.data]);

  useEffect(() => {
    if (certificationId == null) {
      return;
    }

    switch (certificationId) {
      case "OUYOU":
        setMetaProp(metaAnswerAfternoonForHelmet.OUYOU);
        break;
      case "ITSTRATEGY":
        setMetaProp(metaAnswerAfternoonForHelmet.ITSTRATEGY);
        break;
      case "SYSTEMARCHITECT":
        setMetaProp(metaAnswerAfternoonForHelmet.SYSTEMARCHITECT);
        break;
      case "PUROMANE":
        setMetaProp(metaAnswerAfternoonForHelmet.PUROMANE);
        break;
      case "SEKYUMANE":
        setMetaProp(metaAnswerAfternoonForHelmet.SEKYUMANE);
        break;
      case "NESUPE":
        setMetaProp(metaAnswerAfternoonForHelmet.NESUPE);
        break;
      case "DBSPE":
        setMetaProp(metaAnswerAfternoonForHelmet.DBSPE);
        break;
      case "ENVEDETTO":
        setMetaProp(metaAnswerAfternoonForHelmet.ENVEDETTO);
        break;
      case "SEKISUPE":
        setMetaProp(metaAnswerAfternoonForHelmet.SEKISUPE);
        break;
      case "ITSERVICE":
        setMetaProp(metaAnswerAfternoonForHelmet.ITSERVICE);
        break;
      case "SYSKAN":
        setMetaProp(metaAnswerAfternoonForHelmet.SYSKAN);
        break;
      default:
    }
  }, [certificationId]);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    tabKey: string
  ) => {
    setPulldownValue("all");
    setExamStage(tabKey);
  };

  const answer = () => {
    navigate(
      `/answer-afternoon-form?pageId=${certificationId}&seazon=${seazonId}`
    );
  };

  const [certification, setCertification] = useState<CertificationType | null>(
    null
  );

  const getCertificationContext = useContext(GetCertificationContext);

  useEffect(() => {
    const params = {
      pageId: certificationId,
      seazon: seazonId,
    };

    getCertificationContext?.refetch?.(params);
  }, []);

  useEffect(() => {
    if (getCertificationContext?.state?.data?.[0] == null) {
      return;
    }
    setCertification(getCertificationContext.state.data[0]);
  }, [[getCertificationContext.state.data]]);

  const openModal = (answer: FetchAnswerAfternoonResponse) => {
    setSelectId(answer.id);
    setState(true);
  };

  const handleForm = (e: TextFormResult) => {
    if (e.value === "all") {
      setDisplayedAftrenoonAnswers(aftrenoonAnswers);
      return;
    }
    const selectedAnswer = aftrenoonAnswers?.filter(
      (a) => a.section === e.value
    );
    setDisplayedAftrenoonAnswers(selectedAnswer ?? aftrenoonAnswers);
  };

  return (
    <div className={styles.container}>
      {metaProp && (
        <Helmet
          title={metaProp.title}
          meta={[{ name: "description", content: metaProp.description }]}
        />
      )}
      <HeaderLayout
        title={createTitle(certification, seazonId)}
        backgroundColor="#8C7968"
        color="white"
        headerIcon={logoImage}
        isMenu={true}
      >
        <div className={styles.titleContainer}>
          <div>みんなの解答結果(午後)</div>
          <div className={styles.titleContainer}>
            <Button
              variant="contained"
              className={styles.submitButton}
              color="success"
              onClick={() => answer()}
            >
              回答はこちらから
            </Button>
          </div>
          <div className={styles.filterArea}>
            <div className={styles.title}>回答をフィルタ</div>
            <Pulldown
              id={"section"}
              name={"section"}
              title="大問"
              items={filterAnswer}
              selectedValue={pulldownValue}
              handlePulldownChange={handleForm}
            ></Pulldown>
          </div>
          {/* tabを表示するのは応用とせきスペ以外(20240308時点) */}
          {certificationId &&
            !AFTERNOON_UNDIVIDED.includes(certificationId) && (
              <div className={styles.tabArea}>
                <TabContext value={examStage}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} className={styles.tabList}>
                      <Tab
                        label="午後Ⅰ"
                        value={"afternoon1"}
                        className={styles.tab}
                      />
                      <Tab
                        label="午後Ⅱ"
                        value={"afternoon2"}
                        className={styles.tab}
                      />
                    </TabList>
                  </Box>
                </TabContext>
              </div>
            )}
        </div>
        <div className={styles.content}>
          {displayedAftrenoonAnswers?.length === 0 && (
            <div className={styles.noPost}>
              <div>投稿がありません</div>
              <SentimentVeryDissatisfiedIcon className={styles.noPostIcon} />
            </div>
          )}
          {displayedAftrenoonAnswers?.map((afternoonAnswer, index) => (
            <div className={styles.answerContainer} key={index}>
              <div className={styles.title}>
                <div className={styles.userArea}>
                  <div className={styles.userNameArea}>
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        fontSize: 16,
                        lineHeight: 32,
                      }}
                    ></Avatar>
                    <div className={styles.userName}>
                      {afternoonAnswer.userName}
                    </div>
                  </div>
                  <div onClick={() => openModal(afternoonAnswer)}>
                    <DeleteForeverIcon sx={{ fontSize: 32 }} />
                  </div>
                </div>
                <div className={styles.answerTitle}>
                  <div className={styles.parts}>
                    {
                      sectionMapper[
                        afternoonAnswer.section as keyof typeof sectionMapper
                      ]
                    }
                  </div>
                  <div className={styles.difficulty}>
                    {difficultyMapper[
                      afternoonAnswer.difficulty as keyof typeof difficultyMapper
                    ] ?? "例年通り"}
                  </div>
                </div>
                <div className={styles.postTimeArea}>
                  <div>{toYYYYMMDDWithSlah(afternoonAnswer.createDate)}</div>
                  <div>{toHHMM(afternoonAnswer.createDate)}</div>
                </div>
              </div>
              <div className={styles.answer}>{afternoonAnswer.answer}</div>
            </div>
          ))}
        </div>
      </HeaderLayout>
      <AnswerDeleteModal id={selectId ?? ""}></AnswerDeleteModal>
    </div>
  );
};

export default AnswerAfternoonList;
