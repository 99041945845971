import SendIcon from "@mui/icons-material/Send";
import {
  ChangeEvent,
  ChangeEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./ChatFooter.module.scss";
import {
  GetChatMessageContext,
  GetMessageParams,
  PostChatMassageRequest,
  PostChatMessageContext,
} from "../../hooks/reducer/ChatReducer";
import { useLocation } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CancelIcon from "@mui/icons-material/Cancel";
import { Message } from "../../pages/chat-page/Chat";
import CloseIcon from "@mui/icons-material/Close";

type ChatFooterProps = {
  background?: string;
  message?: Message | null;
  scroll?: (behavior: "smooth") => void;
  setReplyMessage: (message: Message | null) => void;
};

const ChatFooter = (props: ChatFooterProps) => {
  const [content, setContent] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [disable, setDisable] = useState(true);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const handleChange = (event: any) => {
    setContent(event.target.value);
    setDisable(event.target.value.trim("\n").length === 0);
  };
  const getChatMessageContext = useContext(GetChatMessageContext);
  const postChatMessageContext = useContext(PostChatMessageContext);

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const certificationId = query?.get("pageId");

  useEffect(() => {
    const element = textAreaRef.current;
    if (!element) {
      return;
    }

    element.style.height = "auto";
    element.style.height = `calc(${element.scrollHeight}px - 10px)`;
  }, [content]);

  useEffect(() => {
    const data = postChatMessageContext?.state?.data;

    if (data == null) {
      return;
    }

    const getParams: GetMessageParams = {
      pageId: certificationId,
    };

    getChatMessageContext?.refetch?.(getParams);
    setContent("");
    setDisable(true);
  }, [postChatMessageContext]);

  const submit = async () => {
    const params: PostChatMassageRequest = {
      pageId: certificationId,
      message: content,
      replyId: props.message?._id,
      replyMessage: props.message?.message,
    };

    postChatMessageContext?.post?.(params);
    props.setReplyMessage(null);
  };

  //   一応残す
  //   const uploadFile = (event: ChangeEvent<HTMLInputElement>) => {
  //     const file = event.target.files?.[0];

  //     if (file == null || file.type === "") {
  //       return;
  //     }

  //     setFile(file);
  //     setContent("");
  //     setDisable(false);
  //   };

  return (
    <>
      <div className={styles.footerWrapper}>
        {!props.message && (
          <div className={styles.arrowIconWrapper}>
            <div
              className={styles.arrowIcon}
              onClick={() => props.scroll?.("smooth")}
            >
              <ArrowDownwardIcon sx={{ fontSize: "25px", color: "#FFFFFF" }} />
            </div>
          </div>
        )}
        <div
          style={{
            background: props.background,
          }}
        >
          {props.message && (
            <>
              <div className={styles.reply}>
                <div
                  className={styles.cancel}
                  onClick={() => props.setReplyMessage(null)}
                >
                  <CloseIcon sx={{ fontSize: "1rem" }} />
                </div>
                <div className={styles.id}>@{props.message._id.slice(-5)}</div>
                <div className={styles.message}>{props.message.message}</div>
              </div>
            </>
          )}
          <div className={styles.inputArea}>
            {/* 一応残しておく */}
            {/* <div
              className={styles.fileIcon}
              onClick={() => inputRef.current?.click()}
            >
              <AttachFileIcon sx={{ fontSize: "1.5rem", color: "#333" }} />
              <input
                hidden
                type="file"
                ref={inputRef}
                onChange={uploadFile}
                accept="image/*"
              />
            </div> */}
            <div className={styles["submit-wrapper"]}>
              {/* {file ? (
                <div className={styles.fileContainer}>
                  <div className={styles.imageLayout}>
                    <img
                      src={window.URL.createObjectURL(file)}
                      alt="attachedImage"
                      className={styles.image}
                    />
                    <div
                      className={styles.cancelIcon}
                      onClick={() => setFile(null)}
                    >
                      <CancelIcon sx={{ color: "white", fontSize: "1.2rem" }} />
                    </div>
                  </div>
                  <div className={styles.fileSelected}>画像選択中</div>
                </div>
              ) : ( */}
              <textarea
                ref={textAreaRef}
                rows={1}
                onChange={handleChange}
                value={content}
                className={styles.input}
              />
              <div className={styles.submit}>
                {disable ? (
                  <SendIcon sx={{ fontSize: "1.5rem", color: "grey" }} />
                ) : (
                  <SendIcon
                    sx={{ fontSize: "1.5rem", color: "rgb(91 153 237)" }}
                    onClick={() => submit()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatFooter;
