// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AnswerMorningListPage_container__SAVDr {
  width: 100%;
  height: 100%;
}

.AnswerMorningListPage_answer__sBg1R {
  height: calc(100% - 4rem);
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/answer-morning-list-page/AnswerMorningListPage.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,yBAAA;EACA,cAAA;AACF","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n}\n\n.answer {\n  height: calc(100% - 4rem);\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AnswerMorningListPage_container__SAVDr`,
	"answer": `AnswerMorningListPage_answer__sBg1R`
};
export default ___CSS_LOADER_EXPORT___;
