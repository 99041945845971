import React, { ChangeEvent, useState } from "react";
import classes from "./RadioButton.module.scss";

export type RadioItems = {
  items: RadioType[];
  label: string;
  value: string | null;
  name: string;
  id: string;
  onChange: (args: RadioResult) => void;
};

export type RadioResult = {
  status: boolean;
  value: string;
  name: string;
};

export type RadioType = {
  display: string;
  value: string;
};

const RadioButton = (props: RadioItems) => {
  const [value, setValue] = useState(props.value);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    props.onChange({ status: true, value: e.target.value, name: props.name });
  };

  const checked = (item: RadioType) => {
    return item.value === value ? { backgroundColor: "#e9917b" } : {};
  };

  return (
    <div className={classes.container}>
      <div className={classes.label}>{props.label}</div>
      <div className={classes.inputContainer}>
        {props.items.map((item, index) => (
          <div
            key={`${props.id}-${index}`}
            className={classes.inputArea}
            style={checked(item)}
            onChange={onChange}
          >
            <input
              className={classes.input}
              type="radio"
              id={`${props.id}-${index}`}
              value={item.value}
              checked={item.value === value}
              readOnly
            />
            <label
              htmlFor={`${props.id}-${index}`}
              className={classes.inputLabel}
            >
              {item.display}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioButton;
