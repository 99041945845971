import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ReportMessage.module.scss";
import { useLocation } from "react-router-dom";
import { GetReportContext } from "../../hooks/reducer/ReportReducer";
import { Report } from "../../pages/report-page/Report";
import SimpleButton from "../../atoms/button/SimpleButton";

interface ReportProps {
  reports: Report[];
}

const ReportMessage = ({ reports }: ReportProps) => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const certificationId = query?.get("pageId");
  const reportEndRef = useRef<HTMLDivElement>(null);
  const getReportContext = useContext(GetReportContext);

  useEffect(() => {
    scrollBottom();
  }, [getReportContext]);

  const scrollBottom = (smooth?: "smooth") => {
    reportEndRef.current?.scrollIntoView({
      behavior: smooth,
      block: "center",
    });
  };

  const post = () => {
    navigate(`/report-form?pageId=${certificationId}`);
  };

  const formatScore = (score: number | undefined | null): string => {
    if (score === null || score === undefined || score === 0) {
      return "-"; // スコアが null, undefined, または 0 の場合は "-" を返す
    }
    return score.toString(); // それ以外の場合はスコアを文字列として返す
  };

  return (
    <>
      <div className={styles.reportContainer}>
        {reports.map((r, index) => (
          <div className={styles.reportBox} key={index}>
            <div className={styles.reportWrapper}>
              <h3 className={styles.titleArea}>
                <span className={styles.seazon}>{r.seazonName}</span>
                <span
                  className={`${styles.badge} ${
                    r.resultId === "合格"
                      ? styles.passBadge
                      : r.resultId === "不合格"
                      ? styles.failBadge
                      : ""
                  }`}
                >
                  {r.resultId}
                </span>
              </h3>
              <div className={styles.scoreArea}>
                {certificationId === "OUYOU" ? (
                  // certificationIdがOUYOUの場合
                  <>
                    <span className={styles.label}>午前</span>
                    <span className={styles.score}>
                      {formatScore(r.morning1Score)}点
                    </span>
                    <span className={styles.label}>午後</span>
                    <span className={styles.score}>
                      {formatScore(r.afternoon1Score)}点
                    </span>
                  </>
                ) : (
                  <>
                    <span className={styles.label}>午前I</span>
                    <span className={styles.score}>
                      {formatScore(r.morning1Score)}点
                    </span>
                    <span className={styles.label}>午前II</span>
                    <span className={styles.score}>
                      {formatScore(r.morning2Score)}点
                    </span>
                    <span className={styles.label}>午後I</span>
                    <span className={styles.score}>
                      {formatScore(r.afternoon1Score)}点
                    </span>
                    <span className={styles.label}>午後II</span>
                    <span className={styles.score}>
                      {formatScore(r.afternoon2Score)}点
                    </span>
                  </>
                )}
              </div>
              <div className={styles.messageArea}>
                <table className={styles.tableBody}>
                  <tbody>
                    <tr>
                      <th className={styles.tableHeader}>勉強時間</th>
                      <td className={styles.tableData}>
                        {r.studyPeriod || "未回答"}
                      </td>
                    </tr>
                    <tr>
                      <th className={styles.tableHeader}>勉強方法</th>
                      <td className={styles.tableData}>
                        {r.studyMethod || "未回答"}
                      </td>
                    </tr>
                    <tr>
                      <th className={styles.tableLastHeader}>感想</th>
                      <td className={styles.tableLastData}>
                        {r.comment || "未回答"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}

        {/** refを利用して初期表示を一番下にする */}
        <div ref={reportEndRef}></div>
      </div>

      <div className={styles.button}>
        <SimpleButton
          label="投稿する"
          width="80%"
          color="darkGray"
          onClick={post}
        ></SimpleButton>
      </div>
    </>
  );
};

export default ReportMessage;
